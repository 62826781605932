import { Section } from "components/layout/Section";

import iconItem1 from "assets/svg/item-1.svg";
import iconItem2 from "assets/svg/item-2.svg";
import iconItem3 from "assets/svg/item-3.svg";
import iconItem4 from "assets/svg/item-4.svg";
import iconItem5 from "assets/svg/item-5.svg";

import PrevidenceItem from "components/organisms/for-you/PrevidenceItem";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import qrCode from "assets/images/qrCode.png";
import appportabilidade from "assets/images/app-portabilidade.png";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section2 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });
  return (
    <Section>
      <div className="flex flex-col gap-4 lg:gap-24 lg:flex-row">
        <div className="flex flex-col items-start max-w-[628px] flex-1">
          <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
            <h5 className="mb-4 text-[40px] font-semibold text-primaryTextBase">
              Traga sua previdência para o Mio em poucos passos
            </h5>
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
            <p className="text-xl font-semibold text-primaryTextBase mb-14">
              Veja como é simples solicitar a portabilidade do seu plano.
            </p>
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1000)}>
            <PrevidenceItem
              icon={iconItem1}
              title="Baixe o app Mio e crie uma conta"
              description="Em poucos cliques você estará pronto para usar o app."
            />
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1200)}>
            <PrevidenceItem
              icon={iconItem2}
              title="Descubra o seu plano ideal"
              description="Informe seus objetivos e capacidade financeira, e receba sugestões de planos feitas por nosso Assessor de Previdência."
            />
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1400)}>
            <PrevidenceItem
              icon={iconItem3}
              title="Contrate o seu plano Mio"
              description="Uma vez o plano escolhido, basta nos informar alguns dados para contratação."
            />
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1600)}>
            <PrevidenceItem
              icon={iconItem4}
              title="Solicite a portabilidade"
              description="Durante o processo de adesão, solicite a portabilidade do seu plano atual para o novo. Informe os dados sobre o plano e deixe o restante conosco."
            />
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1800)}>
            <PrevidenceItem
              icon={iconItem5}
              title="Acompanhe a situação da portabilidade"
              description="Fique tranquilo e acompanhe na tela do app as atualizações até que seu dinheiro seja portado para o Mio."
            />
          </Animation>
        </div>

        <div className="flex flex-col">
          <Animation ref={ref} animationConfig={rightToLeftSpring(800)}>
            <img
              src={appportabilidade}
              alt="Tela de portabilidadde do app Mio"
            />
          </Animation>

          <Animation ref={ref} animationConfig={rightToLeftSpring(800)}>
            <div className="flex-col items-center hidden gap-4 text-center lg:block">
              <h4 className="text-base font-semibold text-primaryTextBase">
                Baixe o app e comece a investir
              </h4>

              <div className="flex flex-col items-center gap-3 mt-5 ml-40 lg:flex-row lg:items-start lg:gap-7">
                <div className="mt-1">
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlayBadge}
                      alt="Google Play"
                      style={{ width: "150px" }}
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appStoreBadge}
                      alt="App Store"
                      className="mt-3"
                      style={{ width: "150px" }}
                    />
                  </a>
                </div>

                <div className="ml-3 lg:ml-0">
                  <img
                    src={qrCode}
                    alt="QR Code para download"
                    className="hidden lg:block"
                  />
                </div>
              </div>
            </div>
          </Animation>
        </div>

        <Animation ref={ref} animationConfig={rightToLeftSpring(800)}>
          <div className="flex justify-center mt-4 mb-8 text-center lg:hidden lg:justify-start">
            <div className="flex flex-col gap-4">
              <h4 className="text-base font-semibold text-primaryTextBase">
                Baixe o app e comece a investir
              </h4>

              <div className="flex justify-between gap-3 lg:gap-7">
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlayBadge} alt="Google Play" />
                </a>

                <a
                  href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appStoreBadge} alt="App Store" />
                </a>
              </div>
            </div>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section2;
