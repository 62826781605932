import { Section } from "components/layout/Section";
import icon1 from "assets/svg/for-you-1.svg";
import icon2 from "assets/svg/for-you-2.svg";
import icon3 from "assets/svg/for-you-3.svg";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";
import { fadeIn } from "utils/Animation.util";

const Section3 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Section>
      <div className="flex flex-col items-center justify-around gap-10 lg:flex-row">
        <Animation ref={refView} animationConfig={leftToRightSpring(600)}>
          <div className="bg-[url('../assets/images/for-you/image-1.png')] w-[302px] h-[254px]">
            <div className="flex flex-col gap-4 w-1/2 bg-[#FFD2A3] rounded-ee-[100px] h-full p-4">
              <img src={icon1} alt="Icon 1" className="w-6 h-6" />

              <p className="text-xs font-medium text-primaryTextBase">
                Viva a aposentadoria com mais tranquilidade, garantindo uma
                renda extra todo mês
              </p>
            </div>
          </div>
        </Animation>

        <Animation ref={refView} animationConfig={fadeIn(600)}>
          <div className="bg-[url('../assets/images/for-you/image-2.png')] w-[302px] h-[254px]">
            <div className="flex flex-col gap-4 w-1/2 bg-[#AAFFD5] rounded-ee-[100px] h-full p-4">
              <img src={icon2} alt="Icon 2" className="w-6 h-6" />

              <p className="text-xs font-medium text-primaryTextBase">
                Alcance objetivos com prazos mais curtos como a compra da casa
                dos seus sonhos ou o pagamento da faculdade de seus filhos
              </p>
            </div>
          </div>
        </Animation>

        <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
          <div className="bg-[url('../assets/images/for-you/image-3.png')] w-[302px] h-[254px]">
            <div className="flex flex-col gap-4 w-1/2 bg-[#B9C1E8] rounded-ee-[100px] h-full p-4">
              <img src={icon3} alt="Icon 3" className="w-6 h-6" />

              <p className="text-xs font-medium text-primaryTextBase">
                Prepare o seu planejamento sucessório, oferecendo mais
                tranquilidade para aqueles que mais importam
              </p>
            </div>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section3;
