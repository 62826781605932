import { Section } from "components/layout/Section";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section3 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Section yPadding="py-0 px-0">
      <div className="hidden sm:flex flex-col gap-4 lg:gap-8 relative bg-[url('../assets/images/banner-portabilidade.png')] bg-left lg:bg-center bg-no-repeat bg-cover h-96 w-[90%] lg:w-auto lg:h-[549px] px-10 py-10 lg:px-20 lg:pt-20 pb-36 mx-auto lg:mx-0 rounded-3xl">
        <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
          <h3 className="text-xl md:text-3xl lg:text-[52px] text-white font-semibold lg:max-w-[500px] md:max-w-[350px] leading-[120%] font-[Montserrat]">
            Aproveite todo o potencial do seu dinheiro com o Mio
          </h3>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
          <p className="text-base font-medium text-white lg:max-w-[500px] md:max-w-[350px]">
            Traga sua previdência para um lugar onde ela será tratada de forma
            única.
          </p>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(1000)}>
          <div className="mt-6 lg:mt-0">
            <Link to="/invista">
              <Button xl>Venha para a previdência Mio</Button>
            </Link>
          </div>
        </Animation>
      </div>

      <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
        <div className="block sm:hidden bg-[url('../assets/images/banner-portabilidade.png')] bg-no-repeat bg-cover h-[248px] py-10 px-6 rounded-3xl w-auto">
          <h3 className="text-xl text-white font-semibold max-w-[200px] leading-[120%]">
            Traga sua previdência para um lugar onde ela será tratada de forma
            única.
          </h3>
        </div>
      </Animation>
    </Section>
  );
};

export default Section3;
