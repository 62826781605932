import Divider from "components/atoms/Divider";
import React from "react";

const PrevidenceItem = ({ icon, title, description }: any) => {
  return (
    <>
      <div className="flex items-start gap-4">
        <img src={icon} alt="previdence" />

        <div className="flex flex-col">
          <p className="mb-1 font-medium text-primaryTextBase">{title}</p>
          <p className="text-sm text-primaryTextBase">{description}</p>
        </div>
      </div>

      <Divider className={"my-8"} />
    </>
  );
};

export default PrevidenceItem;
