import { Section } from "components/layout/Section";
import questions from "assets/images/for-you/questions.png";

import article from "assets/svg/article.svg";
import Collapse from "components/atoms/Collapse";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section4 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });
  return (
    <Section>
      <div className="flex flex-col gap-6 mt-10 lg:flex-row">
        <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
          <div className="flex flex-col">
            <h5 className="text-[40px] text-primaryTextBase font-semibold leading-[120%]">
              Sem palavras complicadas
            </h5>

            <p className="text-2xl font-semibold text-primaryTextBase">
              Tire suas dúvidas sobre portabilidade
            </p>

            <img src={questions} alt="Questions" className="mt-20" />
          </div>
        </Animation>

        <div className="flex flex-1 w-full">
          <Animation
            ref={ref}
            animationConfig={{
              ...rightToLeftSpring(600),
              width: "100%",
            }}
          >
            <div className="flex flex-col flex-1 w-full gap-4">
              <Collapse
                icon={article}
                title="Como funciona a portabilidade?"
                answer={
                  <div>
                    <p className="mb-4">
                      A portabilidade nos planos de previdência privada é a
                      possibilidade que o participante tem de transferir o valor
                      total ou parcial dos recursos acumulados no seu plano de
                      previdência para outro plano, mantendo as características
                      e as vantagens tributárias do produto.
                    </p>
                    <p className="mb-4">
                      A portabilidade pode ser feita para planos da mesma
                      instituição onde os recursos do participante já estejam
                      investidos (chamado de portabilidade interna) ou para
                      planos de instituições diferentes (portabilidade externa).
                    </p>
                    <p className="mb-4">
                      Imagine poder escolher o plano de previdência que atenda
                      melhor às suas necessidades e expectativas, sem precisar
                      abrir mão dos recursos que já foram acumulados e sem a
                      incidência de qualquer custo! Com a portabilidade isso é
                      possível!
                    </p>
                  </div>
                }
              />

              <Collapse
                icon={article}
                title="Quais são os motivos para se fazer uma portabilidade?"
                answer={
                  <div>
                    <p className="mb-4">
                      São vários os motivos que você pode ter para optar pela
                      portabilidade do seu plano. Os principais são:
                    </p>
                    <p className="mb-4">
                      <li>
                        Busca por planos com menores custos envolvidos (como por
                        exemplo menor taxa de administração e de performance).
                        Como se trata de investimento de longo prazo, ao
                        transferir seus recursos para um plano com condições
                        mais vantajosas, você pode obter rentabilidades maiores
                        e ampliar seu patrimônio no longo prazo.
                      </li>
                    </p>
                    <p className="mb-4">
                      <li>
                        Portfólio de investimentos alinhado com o seu perfil de
                        risco e objetivos de longo prazo. Ao contratar um plano
                        de previdência privada os recursos aplicados pelo
                        investidor são alocados em fundos de investimento que
                        podem ter diferentes estratégias. É fundamental que essa
                        alocação seja feita respeitando o perfil de risco e
                        objetivos de longo prazo do investidor. Contar com
                        produtos diferenciados e geridos por profissionais
                        especializados fará toda a diferença para o alcance dos
                        objetivos do participante.
                      </li>
                    </p>
                    <p className="mb-4">
                      <li>
                        Busca por uma instituição com estrutura de sistema,
                        atendimento e suporte mais adequada às necessidades da
                        empresa. No Mio Vinci Partner, todo o processo desde o
                        cadastramento do participante, passando pela escolha dos
                        fundos e realocação dos investimentos, é feito de forma
                        simples e rápida, através do aplicativo. Em caso de
                        dúvidas, nossos especialistas estão à disposição para
                        esclarece-las.
                      </li>
                    </p>
                  </div>
                }
              />

              <Collapse
                icon={article}
                title="Como faço para solicitar a portabilidade?"
                answer={
                  <div>
                    <p className="mb-4">
                      Para iniciar o processo de portabilidade, você deverá
                      entrar em contato com a instituição financeira responsável
                      pelo plano de previdência de origem e solicitar as
                      informações necessárias para a transferência dos recursos:
                    </p>
                    <p className="mb-4">
                      <li>
                        Nome da instituição onde se encontra o plano a ser
                        portado;
                      </li>
                    </p>
                    <p className="mb-4">
                      <li>Código do certificado;</li>
                    </p>
                    <p className="mb-4">
                      <li>Nome;</li>
                    </p>
                    <p className="mb-4">
                      <li>CNPJ e código SUSEP do fundo de origem;</li>
                    </p>
                    <p className="mb-4">
                      <li>Modalidade e tipo de tributação do plano origem;</li>
                    </p>
                    <p className="mb-4">
                      Essas informações costumam estar disponíveis também no
                      extrato do participante e em outros documentos fornecidos
                      pela instituição financeira, como o regulamento do plano e
                      o informe de rendimentos. Adicionalmente você deverá
                      indicar o valor a ser portado, se total ou parcial.
                    </p>
                  </div>
                }
              />

              <Collapse
                icon={article}
                title="Em quanto tempo a portabilidade é concluída?"
                answer={
                  <div>
                    <p className="mb-4">
                      O prazo de efetivação da portabilidade pode levar entre 5
                      a 10 dias úteis, contados a partir do pedido realizado em
                      nosso app. Este prazo pode ser estendido, caso haja alguma
                      inconsistência nas informações repassadas pelo
                      participante.
                    </p>
                    <p className="mb-4">
                      Você poderá acompanhar todo o processo de portabilidade
                      por meio do app Mio. Solicite hoje a portabilidade de seu
                      plano para o Mio.
                    </p>
                  </div>
                }
              />
            </div>
          </Animation>
        </div>
      </div>
    </Section>
  );
};

export default Section4;
