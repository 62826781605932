import { Section } from "components/layout/Section";
import { Background } from "components/atoms/Background";

import calculatorImage from "assets/svg/calculatorImage.svg";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";
import { fadeIn } from "utils/Animation.util";
const Section5 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Animation ref={refView} animationConfig={fadeIn(600)}>
      <Section>
        <Background color="bg-[#004D5C] rounded-md  rounded-ee-[100px]">
          <div className="flex flex-col lg:flex-row gap-14 px-10 lg:px-20 py-10 lg:max-h-[320px] lg:overflow-visible">
            <div className="flex flex-col max-w-[628px]">
              <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
                <h5 className="text-[28px] text-white font-semibold leading-[120%] mb-4">
                  Aproveite o benefício fiscal do seu PGBL
                </h5>
              </Animation>

              <img
                className="block py-6 lg:hidden"
                src={calculatorImage}
                alt="Calculadora PGBL"
              />

              <Animation ref={refView} animationConfig={rightToLeftSpring(800)}>
                <p className="mb-8 text-base text-white">
                  Invista de forma inteligente e reduza seu IR ao aplicar até
                  12% da sua renda bruta anual no seu plano PGBL Mio. Utilize a
                  calculadora para saber quanto você precisa investir ainda
                  neste ano e economize.
                </p>
              </Animation>

              <Animation
                ref={refView}
                animationConfig={rightToLeftSpring(1000)}
              >
                <Link to="/calculator">
                  <Button xl>Calcule o investimento necessário</Button>
                </Link>
              </Animation>
            </div>

            <div className="hidden ml-5 lg:-mt-12 lg:block">
              <img src={calculatorImage} alt="Calculadora PGBL" />
            </div>
          </div>
        </Background>
      </Section>
    </Animation>
  );
};

export default Section5;
