import React from "react";
import { Section } from "components/layout/Section";
import notFoundImage from "assets/images/404-page-not-found.svg";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="overflow-auto antialiased text-black">
      <div className="h-80 lg:h-[380px]">
        <Section yPadding="py-0">
          {/* <Navbar logo={<img src={logo} alt="" width={168} />} /> */}

          <div className="flex flex-col items-center mt-20">
            <h2 className="text-3xl font-semibold text-center lg:text-5xl text-neutralTextLight">
              Página não encontrada
            </h2>

            <div className="flex items-center gap-2">
              <Link to={"/"}>
                <p className="text-base cursor-pointer lg:text-lg text-neutralTextLightIntense">
                  Inicio
                </p>
              </Link>
            </div>
          </div>
        </Section>
      </div>

      <Section yPadding="pt-0 pb-20">
        <div className="flex justify-center">
          <img
            src={notFoundImage}
            alt="Página não encontrada"
            className="lg:w-[500px]"
          />
        </div>
      </Section>
    </div>
  );
};

export default NotFound;
