import React from "react";
import { Section } from "components/layout/Section";
import { Navbar } from "components/templates/shared/Navbar";

type Props = {
  title: string;
  subtitle?: string;
  h?: string;
};

const HeroPages: React.FC<Props> = ({ title, subtitle, h }) => {
  return (
    <>
      <Navbar isDark />

      <div className={`pt-20 h-${h || '80'} lg:h-[380px]}`}>
        <Section yPadding="py-0">
          <div className="flex flex-col items-center mt-20">
            <h2 className="text-3xl font-semibold text-center lg:text-5xl text-neutralTextLight">
              {title}
            </h2>

            <div className="flex items-center gap-2">
              <p className="text-lg text-neutralTextLightIntense">
                {subtitle}
              </p>
            </div>
          </div>
        </Section>
      </div>
    </>
  );
};

export default HeroPages;
