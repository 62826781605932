import { Section } from "components/layout/Section";
import { Footer } from "components/templates/shared/Footer";
import HeroPages from "components/organisms/HeroPages";

const ExclusionAccount = () => {
  return (
    <div className="overflow-auto antialiased text-black">
      <HeroPages title="Como Excluir sua Conta" h="50"/>

      <Section>
          <div className="flex flex-col flex-1 text-center pt-7 lg:text-left">
            <h5 className="mb-6 text-xl lg:text-4xl font-semibold text-neutralTextLight leading-[120%] lg:text-left">
              Para excluir sua conta no{" "}
              <span className="text-primary">Mio:</span>
            </h5>

            <p className="text-base lg:text-lg">
                &bull; Para excluir sua conta: No aplicativo, entre em <b>Menu &gt; Minha Conta &gt; Encerramento de Conta.</b><br/><br/>
                &bull; Exclusão de dados pessoais: Seus dados ficarão armazenados por <b>6 meses após a exclusão da conta, para fins de auditoria.</b><br/><br/>
                &bull; Para os casos em que o cliente chegou a contratar um Plano de Previdência, os dados ficarão armazenados por <b> 5 anos após a exclusão da conta, 
                para fins de auditoria.</b><br/><br/>
                &bull; Em caso de dúvidas, entre em contato conosco através do email <b><a href="mailto:sac-mio@vincipartners.com>">SAC MIO &lt;sac-mio@vincipartners.com&gt;</a></b>.            
            </p>
          </div>
      </Section>

      <Section>
        <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 justify-around bg-[#FEF3F5] rounded-3xl py-16 px-10">
          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">Aplicativo</p>
            <p className="text-lg text-neutralTextLightIntense">
              <a href="https://play.google.com/store/apps/details?id=br.com.vinciprev">Mio Vinci Partners, na Google Play</a>
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">Desenvolvedor</p>
            <p className="text-lg text-neutralTextLightIntense">
              <a href="https://play.google.com/store/apps/developer?id=Vinci+Retirement+Services+Tech">Vinci Retirement Services Tech</a>
            </p>
          </div>
        </div>
      </Section>

      <Footer />
    </div>
  );
};

export default ExclusionAccount;
