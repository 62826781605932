import { Footer } from "components/templates/shared/Footer";
import { Section } from "components/layout/Section";
import { forwardRef, useState } from "react";
import { Navbar } from "components/templates/shared/Navbar";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import qrCode from "assets/images/qrCode.png";
import appHome from "assets/images/appHome.png";
import calculatorIcon from "assets/svg/calculator-icon.svg";
import helpIcon from "assets/svg/help-icon.svg";
import leoCalculator from "assets/svg/leo-calculator.svg";
import arrowBack from "assets/svg/arrow-back.svg";

const Calculator =
    forwardRef<
        HTMLDivElement>
        ((props, ref) => {
            const [rendaBrutaAnual, setRendaBrutaAnual] = useState("");
            const [valorInvestidoPGBL, setValorInvestidoPGBL] = useState("");
            const [mostrarErro, setMostrarErro] = useState(false);
            const [loading, setLoading] = useState(false);
            const [resultado, setResultado] = useState("");
            const [exibirResultado, setExibirResultado] = useState(false);
            const [iranulaCalculo, setIranulaCalculo] = useState(0);
            const [ireconomizadoCalculo, setIreconomizadoCalculo] = useState(0);
            const [valpgblCalculo, setValpgblCalculo] = useState(0);

            const formatarMoeda = (valor: number) => {
                let valorFormatado = valor.toFixed(2).split(".");
                valorFormatado[0] = valorFormatado[0].split(/(?=(?:...)*$)/).join(".");
                return "R$ " + valorFormatado.join(",");
            };

            const formatarParaMoedaBrasileira = (valor: string): string => {
                let valorNumerico = valor.replace(/\D/g, "");
                if (valorNumerico === "") {
                    return "";
                }

                valorNumerico = (parseInt(valorNumerico) / 100).toFixed(2);
                if (isNaN(parseFloat(valorNumerico))) {
                    return "";
                }

                return "R$ " + valorNumerico.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
            };

            const handleRendaBrutaAnualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const valorFormatado = formatarParaMoedaBrasileira(event.target.value);
                setRendaBrutaAnual(valorFormatado);
            };

            const handleValorInvestidoPGBLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const valorFormatado = formatarParaMoedaBrasileira(event.target.value);
                setValorInvestidoPGBL(valorFormatado);
            };

            const calcularResultado = () => {
                if (!rendaBrutaAnual || !valorInvestidoPGBL) {
                    setMostrarErro(true);
                    return;
                }
                setMostrarErro(false);
                setLoading(true);

                setTimeout(() => {
                    let rendaBruta = parseFloat(rendaBrutaAnual.replace(/R\$|\./g, "").replace(",", "."));
                    let investimentoPgbl = parseFloat(valorInvestidoPGBL.replace(/R\$|\./g, "").replace(",", "."));
                    let aliquotaIr = 0;
                    let deducao = 0;
                    let iranulaCalculo = 0;
                    let ireconomizadoCalculo = 0;
                    let valpgblCalculo = 0;

                    if (rendaBruta >= 22847.77 && rendaBruta <= 33919.8) {
                        aliquotaIr = 0.075;
                        deducao = 1713.58;
                    } else if (rendaBruta >= 33919.81 && rendaBruta <= 45012.6) {
                        aliquotaIr = 0.15;
                        deducao = 4257.57;
                    } else if (rendaBruta >= 45012.61 && rendaBruta <= 55976.16) {
                        aliquotaIr = 0.225;
                        deducao = 7633.51;
                    } else if (rendaBruta > 55976.16) {
                        aliquotaIr = 0.275;
                        deducao = 10432.32;
                    }

                    iranulaCalculo = (rendaBruta - investimentoPgbl) * aliquotaIr - deducao;
                    if (investimentoPgbl > rendaBruta * 0.12) {
                        iranulaCalculo = 0.88 * rendaBruta * aliquotaIr - deducao;
                    }

                    if (rendaBruta * 0.12 * aliquotaIr - investimentoPgbl * aliquotaIr < 0) {
                        ireconomizadoCalculo = 0;
                    } else {
                        ireconomizadoCalculo = rendaBruta * 0.12 * aliquotaIr - investimentoPgbl * aliquotaIr;
                    }

                    if (rendaBruta >= 22847.77) {
                        valpgblCalculo = rendaBruta * 0.12 - investimentoPgbl;
                    }

                    let resultadoCalculo =
                        `IR total devido este ano: ${formatarMoeda(iranulaCalculo)}\n` +
                        `Imposto que poderia ser economizado: ${formatarMoeda(ireconomizadoCalculo)}\n` +
                        `Valor necessário de ser aplicado em PGBL: ${formatarMoeda(valpgblCalculo)}`;

                    setResultado(resultadoCalculo);
                    setLoading(false);
                    setExibirResultado(true);
                    setIranulaCalculo(iranulaCalculo);
                    setIreconomizadoCalculo(ireconomizadoCalculo);
                    setValpgblCalculo(valpgblCalculo);
                }, 2000);
            };

            const resetarCalculadora = () => {
                setRendaBrutaAnual("");
                setValorInvestidoPGBL("");
                setMostrarErro(false);
                setLoading(false);
                setResultado("");
                setExibirResultado(false);
            };

            return (
                <>
                    <Navbar />
                    <div ref={ref} className="relative h-[797px] lg:h-[800px] bg-[url('../assets/images/hero3.png')] bg-[right_-22rem_top] lg:bg-top bg-no-repeat bg-cover lg:px-10">
                        <Section yPadding="py-0">
                            <div className="flex flex-col lg:flex-row justify-between mb-14 lg:mb-20 pt-24 lg:pt-28">
                                <div className="pt-6 lg:pt-12">
                                    <h1 className="mb-6 text-[44px] sm:text-[72px] font-semibold font-[Montserrat] text-white whitespace-pre-line leading-[120%] max-w-[750px] lg:text-[80px]">
                                        Aproveite os benefícios de sua <span className="text-primary">previdência</span>
                                    </h1>
                                    <div className="mb-7 lg:mb-10 md:max-w-[628px]">
                                        <p className="text-base leading-6 text-white lg:leading-8 lg:text-lg">
                                            Calcule quanto você precisa investir em seu plano PGBL para aproveitar o máximo do benefício fiscal e reduzir o imposto de renda devido para o próximo ano.
                                        </p>
                                    </div>
                                </div>

                                <div className={`calculator-container ${exibirResultado ? "hidden" : ""}`}>
                                    <style>
                                        {`
          .tooltip {
              display: none;
              position: absolute;
              background-color: #1F3033;
              color: white;
              text-align: left;
              padding: 8px;
              border-radius: 6px;
              z-index: 1;
              max-width: 345px;
              word-wrap: break-word;
              font-size: 12px;
              top: 37%;
          }

          .tooltip::before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 71%;
            transform: translateX(-50%);
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #1F3033 transparent;
        }

          .help-icon:hover + .tooltip {
              display: block;
          }

          @media (max-width: 768px) {
            .tooltip {
                display: none;
                position: absolute;
                background-color: #1F3033;
                color: white;
                text-align: left;
                padding: 8px;
                border-radius: 6px;
                z-index: 1;
                max-width: 315px;
                top: 79%;
                left: 50%;
                transform: translateX(-50%);
            }
        
            .tooltip::before {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent #1F3033 transparent;
            }
        
            .help-icon:hover + .tooltip, .help-icon2:hover + .tooltip2 {
                display: block;
            }
        }
    `}

                                        {`
          .tooltip2 {
              display: none;
              position: absolute;
              background-color: #1F3033;
              color: white;
              text-align: left;
              padding: 8px;
              border-radius: 6px;
              z-index: 1;
              max-width: 345px;
              word-wrap: break-word;
              font-size: 12px;
              top: 50%;
          }

          .tooltip2::before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 95%;
            transform: translateX(-50%);
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #1F3033 transparent;
        }

          .help-icon2:hover + .tooltip2 {
              display: block;
          }

          @media (max-width: 768px) {
            .tooltip2 {
                display: none;
                position: absolute;
                background-color: #1F3033;
                color: white;
                text-align: left;
                padding: 8px;
                border-radius: 6px;
                z-index: 1;
                max-width: 315px;
                top: 93%;
                left: 50%;
                transform: translateX(-50%);
            }
        
            .tooltip2::before {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent #1F3033 transparent;
            }
        
            .help-icon:hover + .tooltip, .help-icon2:hover + .tooltip2 {
                display: block;
            }
        }

        .botao-calcular:hover {
            transform: scale(1.05);
        }
        
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        
        .loading-icon {
            border: 4px solid #f3f3f3;
            border-top: 4px solid #3498db;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            animation: spin 2s linear infinite;
        }
        
        .loading-blur {
            filter: blur(5px);
        }
        
        @keyframes pullEffect {
            from { transform: translateX(-100%); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
        }
        
        .pull-container {
            animation: pullEffect 0.5s ease-out forwards;
        }
        
        .container {
            overflow: hidden; /* Para garantir que a animação aconteça dentro do contêiner */
        }

        @media (max-width: 768px) {
            .botao-voltar {
                left: 28px;
                transform: none;
                top: 420px;
            }
        }

        .custom-input:focus {
            border-color: #00A2C2;
            caret-color: #00A2C2;
            outline: none;
            border-width: 3px;
            font-size: 20px;
        }

        .custom-input {
            color: #052329;
            font-weight: bold;
            padding: 10px;
            border: 3px solid #E0E0E0;
            width: 100%;
        }
        
        .custom-input::placeholder {
            font-size: 20px;
            font-weight: bold;
        }
    `}
                                    </style>
                                    <div style={{ borderBottom: "1px solid #E0E0E0" }}>
                                        <div className="bg-white p-6 rounded-t-lg shadow-md flex items-center md:max-w-[451px]">
                                            <div className="w-14 h-14 rounded-xl bg-violet-300 flex justify-center items-center">
                                                <img src={calculatorIcon} alt="Ícone calculadora" />
                                            </div>
                                            <div className="ml-4">
                                                <h2 className="text-2xl text-emerald-950 font-semibold mb-2 font-montserrat leading-[31.20px]">Calculadora Mio</h2>
                                                <div className="text-emerald-950 text-sm font-normal font-inter leading-tight mb-4">Saiba o valor que você precisa investir</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ borderBottom: "1px solid #E0E0E0" }}>
                                        <div className="bg-white p-6 shadow-md md:max-w-[451px]`}">
                                            <div className="flex items-center mb-4">
                                                <p className="text-emerald-950 text-base font-medium font-inter leading-normal">Qual é a sua renda bruta anual?</p>
                                                <img src={helpIcon} alt="Ícone de ajuda" className="ml-2 help-icon" />
                                                <div className="tooltip">
                                                    Sua renda bruta anual é a soma de todos os seus rendimentos antes dos descontos, recebidos ao longo do ano, sejam eles provenientes de salários, férias, comissões, corretagens, pensões e
                                                    aluguéis.
                                                </div>
                                            </div>
                                            <input type="text" value={rendaBrutaAnual} onChange={handleRendaBrutaAnualChange} className="mb-4 p-2 border rounded-lg custom-input" placeholder="R$ 0,00" />

                                            <div className="flex items-center mb-4">
                                                <p className="text-emerald-950 text-base font-medium font-inter leading-normal">Quanto você investiu em PGBL neste ano?</p>
                                                <img src={helpIcon} alt="Ícone de ajuda" className="ml-2 help-icon2" />
                                                <div className="tooltip2">Insira o valor total das contribuições que você fez para seu PGBL neste ano.</div>
                                            </div>
                                            <input type="text" value={valorInvestidoPGBL} onChange={handleValorInvestidoPGBLChange} className="mb-4 p-2 border rounded-lg custom-input" placeholder="R$ 0,00" />

                                            <p className="text-center text-slate-600 text-sm font-normal font-inter leading-tight mt-3">Caso não tenha contribuído este ano, basta calcular.</p>
                                        </div>
                                    </div>

                                    <div className="bg-white p-6 rounded-b-lg shadow-md md:max-w-[451px]">
                                        {!loading && !resultado && (
                                            <>
                                                <button className="botao-calcular w-full p-3 bg-[#99EEFF] text-center text-gray-800 text-lg font-medium font-['Inter'] leading-7" onClick={calcularResultado}>
                                                    Calcular
                                                </button>
                                                {mostrarErro ? (
                                                    <div className="w-[330px] text-center text-red-600 text-sm font-normal font-['Inter'] leading-tight mt-4">É necessário digitar um valor !!!</div>
                                                ) : (
                                                    <div className="mx-auto w-[330px] text-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight mt-4">
                                                        Você precisa contribuir com a previdência social e realizar a declaração de IR no modelo completo.
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {loading && (
                                            <div className="relative">
                                                <button className="botao-calcular w-full p-3 bg-[#99EEFF] text-center text-gray-800 text-lg font-medium font-['Inter'] leading-7">
                                                    <div className="loading-icon" style={{ margin: "auto" }}></div>
                                                </button>
                                                <div className="mx-auto w-[330px] text-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight mt-4">
                                                    Você precisa contribuir com a previdência social e realizar a declaração de IR no modelo completo.
                                                </div>
                                            </div>
                                        )}
                                        {!loading && resultado.includes("Valor") && (
                                            <>
                                                <div className="text-center">{resultado}</div>
                                                <button onClick={resetarCalculadora} className="botao-voltar">
                                                    <span>&#x2190;</span> {/* Ícone de seta (←) */}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/* Resultado */}
                                <div className={`calculator-container resultado-container ${exibirResultado ? "pull-container" : "hidden"}`}>
                                    <div style={{ borderBottom: "1px solid #E0E0E0" }}>
                                        <div className="bg-white p-6 rounded-t-lg shadow-md md:max-w-[451px] flex justify-between items-center">
                                            <img src={arrowBack} alt="Ícone de voltar" className="cursor-pointer" onClick={resetarCalculadora} style={{ maxWidth: "30px", maxHeight: "30px" }} />
                                            <h2 className="text-emerald-950 text-xl font-semibold font-['Montserrat'] leading-7">Resultado</h2>
                                            <div style={{ width: "30px", height: "30px" }}></div>
                                        </div>
                                    </div>

                                    <div className="bg-white p-6 shadow-md rounded-b-lg md:max-w-[451px]">
                                        {!loading && exibirResultado && (
                                            <div className="mt-8">
                                                <div className="text-left mb-4 border-b border-[#E0E0E0]">
                                                    <div className="flex justify-between items-center w-full text-emerald-950 text-sm font-normal font-['Inter'] leading-tight mb-3">
                                                        <div className="flex flex-col">
                                                            <span>IR total devido este ano</span>
                                                        </div>
                                                        <span className="font-bold text-xl flex-shrink-0 whitespace-nowrap">{formatarMoeda(iranulaCalculo)}</span>
                                                    </div>
                                                </div>
                                                <div className="text-left mb-4 mt-14 border-b border-[#E0E0E0]">
                                                    <div className="flex justify-between items-center w-full text-emerald-950 text-sm font-normal font-['Inter'] leading-tight mb-3">
                                                        <div className="flex flex-col">
                                                            <span>Imposto que poderia</span>
                                                            <span>ser economizado</span>
                                                        </div>
                                                        <span className="font-bold text-xl flex-shrink-0 whitespace-nowrap">{formatarMoeda(ireconomizadoCalculo)}</span>
                                                    </div>
                                                </div>
                                                <div className="text-left mb-4 mt-14">
                                                    <div className="flex justify-between items-center w-full text-emerald-950 text-sm font-normal font-['Inter'] leading-tight mb-3">
                                                        <div className="flex flex-col">
                                                            <span>Valor necessário para</span>
                                                            <span>aplicação extra</span>
                                                        </div>
                                                        <span className="font-bold text-2xl text-cyan-800 flex-shrink-0 whitespace-nowrap">{valpgblCalculo < 0 ? "R$ 0,00" : formatarMoeda(valpgblCalculo)}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-start mt-10">
                                                    <img src={leoCalculator} alt="Ícone do Leo" />
                                                    {valpgblCalculo <= 0 ? (
                                                        <div className="bg-[#99EEFF] p-4 mt-1 ml-8 text-black text-sm w-[451px] rounded-br-lg rounded-tr-lg rounded-bl-lg">
                                                            <strong>Recomendação:</strong>
                                                            <br />
                                                            {valpgblCalculo === 0 ? (
                                                                "Maravilha! Você está aproveitando o máximo do benefício e não precisará realizar outras aplicações neste ano para este plano. Se quiser investir além dos 12%, o melhor seria a aplicação dos valores extras em um plano VGBL."
                                                            ) : (
                                                                <>
                                                                    Tenha atenção! Você investiu <strong>{formatarMoeda(Math.abs(valpgblCalculo))}</strong> a mais no seu plano PGBL.
                                                                </>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="bg-[#99EEFF] p-4 mt-1 ml-8 text-black text-sm w-[451px] rounded-br-lg rounded-tr-lg rounded-bl-lg">
                                                            <strong>Recomendação:</strong>
                                                            <br />
                                                            Para aproveitar o máximo do benefício, faça uma aplicação extra de <strong>{formatarMoeda(valpgblCalculo)}</strong> no seu plano <strong>PGBL Mio</strong> até 22 de dezembro.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Section>
                    </div>

                    <Section yPadding="py-20 lg:py-[140px]">
                        <div ref={ref} className="flex flex-col items-center lg:items-start lg:flex-row mb-4 gap-12 lg:gap-[78px] pt-52 lg:pt-14">
                            <div className="flex flex-col lg:max-w-[302px] mt-11">
                                <h5 className="text-[40px] font-semibold text-primaryTextBase font-[Montserrat] leading-[50px]">Baixe nosso app e invista no seu futuro</h5>

                                <p className="text-lg sm:text-base text-primaryTextBase mt-3">Uma previdência privada de um jeito finalmente eficiente.</p>

                                <div className="justify-center hidden mt-16 lg:flex lg:justify-start">
                                    <div className="flex flex-col gap-4">
                                        <h4 className="text-base font-semibold text-primaryTextBase mt-14">Clique para baixar o app em seu celular</h4>

                                        <div className="flex flex-col lg:flex-row items-center lg:items-start gap-3 lg:gap-7">
                                            <div className="mt-1">
                                                <a href="https://play.google.com/store/apps/details?id=br.com.vinciprev" target="_blank" rel="noreferrer">
                                                    <img src={googlePlayBadge} alt="Google Play" style={{ width: "150px" }} />
                                                </a>
                                                <a href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937" target="_blank" rel="noreferrer">
                                                    <img src={appStoreBadge} alt="App Store" className="mt-3" style={{ width: "150px" }} />
                                                </a>
                                            </div>

                                            <div className="ml-3 lg:ml-0">
                                                <img src={qrCode} alt="QR Code para download" className="hidden lg:block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <img src={appHome} alt="Aplicativo" className="h-96 lg:h-auto mt-5 mx-auto lg:mx-0" />

                                <div className="flex justify-center mt-4 mb-8 text-center lg:hidden lg:justify-start">
                                    <div className="flex flex-col gap-4">
                                        <h4 className="text-base font-semibold text-primaryTextBase">Baixe o app e comece a investir</h4>

                                        <div className="flex justify-between gap-3 lg:gap-7">
                                            <a href="https://play.google.com/store/apps/details?id=br.com.vinciprev" target="_blank" rel="noreferrer">
                                                <img src={googlePlayBadge} alt="Google Play" />
                                            </a>

                                            <a href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937" target="_blank" rel="noreferrer">
                                                <img src={appStoreBadge} alt="App Store" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col flex-1 gap-8 lg:pt-16">
                                <h6 className="mb-4 text-xl font-semibold text-primaryTextBase leading-[120%] font-[Montserrat]">Para investir é simples e rápido</h6>

                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                        <p className="font-medium">1</p>
                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <p className="text-lg font-medium text-primaryTextBase">Baixe o app e crie sua conta</p>

                                        <p className="text-sm sm:text-base text-primaryTextBase">Crie a sua conta com apenas alguns cliques.</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                        <p className="font-medium">2</p>
                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <p className="text-lg font-medium text-primaryTextBase">Descubra seu perfil de investidor</p>

                                        <p className="text-sm sm:text-base text-primaryTextBase">Conservador, moderado ou arrojado? Seu perfil aliado ao seu planejamento.</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                        <p className="font-medium">3</p>
                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <p className="text-lg font-medium text-primaryTextBase">Simule o seu plano ideal</p>

                                        <p className="text-sm sm:text-base text-primaryTextBase">Informe seus objetivos e receba sugestões de planos pensados para você.</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                        <p className="font-medium">4</p>
                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <p className="text-lg font-medium text-primaryTextBase">Contrate e veja seu dinheiro render</p>

                                        <p className="text-sm sm:text-base text-primaryTextBase">Sem taxas escondidas ou letras miúdas. Você no controle do seu futuro.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Section>
                    <Footer />
                </>
            );
        });

export default Calculator;
