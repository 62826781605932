import { Section } from "components/layout/Section";
import { Button } from "components/atoms/Button";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section4 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Section yPadding="py-10 px-0">
      <div className="hidden sm:flex flex-col gap-4 lg:gap-8 relative bg-[url('../assets/images/bannerSejaUmParceiro.png')] bg-left lg:bg-center bg-no-repeat bg-cover h-96 w-[90%] lg:w-auto lg:h-[549px] px-10 py-10 lg:px-20 lg:pt-20 pb-36 mx-auto lg:mx-0 rounded-3xl">
        <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
          <h3 className="text-xl md:text-3xl lg:text-[52px] text-white font-semibold lg:max-w-[500px] md:max-w-[350px] leading-[120%] font-[Montserrat]">
            Oferecemos uma parceria com os resultados
          </h3>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
          <p className="text-base font-medium text-white lg:max-w-[500px] md:max-w-[350px]">
            Fale com um especialista e saiba mais sobre o nosso programa de
            parceiros.
          </p>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(1000)}>
          <div className="mt-6 lg:mt-0">
            <Link to="https://forms.office.com/r/Lhh5aRYAaU" target="_blank">
              <Button xl>Converse com um especialista</Button>
            </Link>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section4;
