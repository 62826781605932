import { Section } from "components/layout/Section";
import { Footer } from "components/templates/shared/Footer";
import aboutSectionBadge from "assets/images/about-section.png";
import HeroPages from "components/organisms/HeroPages";

const YourFuture = () => {
  return (
    <div className="overflow-auto antialiased text-black">
      <HeroPages title="Seu Futuro" />

      <Section>
        <div className="flex flex-col mb-4 lg:flex-row lg:gap-20">
          <div className="flex flex-col flex-1 text-center pt-7 lg:text-left">
            <h5 className="mb-6 text-xl lg:text-4xl font-semibold text-neutralTextLight leading-[120%] lg:text-left">
              O futuro do <span className="text-primary">seu jeito.</span>
            </h5>

            <p className="text-base lg:text-lg text-neutralTextLightIntense">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua
              Utenimad minim veniam, quis nostrud exercitation dolore magna
            </p>
          </div>

          <div className="flex items-center justify-center flex-1 mt-10 lg:mt-0 lg:justify-left">
            <img
              className="w-[80%] md:w-[320px] lg:w-[410px] mb-10 lg:mb-0"
              src={aboutSectionBadge}
              alt="Google Play"
            />
          </div>
        </div>
      </Section>

      <Section>
        <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 justify-around bg-[#FEF3F5] rounded-3xl py-16 px-10">
          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">320+</p>
            <p className="text-lg text-neutralTextLightIntense">
              Complete Projects
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">25+</p>
            <p className="text-lg text-neutralTextLightIntense">
              Cooperation company
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">120+</p>
            <p className="text-lg text-neutralTextLightIntense">
              Happy Clients
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="flex flex-col items-center gap-4 text-center lg:px-24">
          <p className="text-lg font-medium text-primary">Nossa visão</p>
          <h5 className="mb-4 text-2xl font-semibold lg:text-3xl text-neutralTextLight">
            Provide The Creative Solutions
          </h5>
          <p className="text-lg text-neutralTextLightIntense">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad
            minim veniam, quis nostrud exercitation dolore magna
          </p>
        </div>
      </Section>

      <Footer />
    </div>
  );
};

export default YourFuture;
