import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const Collapse = ({ icon, title, answer }: any) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="flex flex-col p-4 border-solid border border-[#D5D9DA] rounded-lg w-full">
      <div
        className="flex flex-1 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <img src={icon} alt="icon" className="w-6 h-6 mr-4" />
        <p className="flex-1 text-[#0F1B20] font-medium">{title}</p>

        <ChevronRightIcon
          width={20}
          color="text-[#0F1B20]"
          className={open ? "rotate-90" : "rotate-0"}
        />
      </div>

      {open && (
        <div className={"pl-10 mt-2"}>
          <p className="text-sm text-[#0F1B20]">{answer}</p>
        </div>
      )}
    </div>
  );
};

export default Collapse;
