import Animation from "components/atoms/Animation";
import { Section } from "components/layout/Section";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";

const Section2 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const topToBottomSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(-150px)",
    config: { duration: 1000 },
  };

  const bottomToUpSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(200px)",
    config: { duration: 1000 },
  };
  return (
    <Section>
      <div className="flex flex-col items-center">
        <Animation ref={ref} animationConfig={topToBottomSpring}>
          <h5 className="mb-14 text-3xl font-semibold text-primaryTextBase leading-[120%] text-center max-w-[628px]">
            Mais benefícios para suas empresas e colaboradores
          </h5>
        </Animation>

        <Animation ref={ref} animationConfig={bottomToUpSpring}>
          <div className="flex flex-row flex-wrap gap-6">
            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Atração e retenção de colaboradores
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Ofereça a previdência Mio para seus colaboradores e veja a
                atratividade e retenção em sua empresa aumentar com este
                benefício.
              </p>
            </div>

            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Benefícios fiscais
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Assim como em planos individuais, as empresas que oferecem o
                plano de previdência Mio para seus colaboradores, pode ter
                incentivos fiscais.
              </p>
            </div>

            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Planos pensados para a sua empresa
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Utilize nossa ferramenta de configuração para montar um plano
                que atenda os interesses de sua empresa e seus colaboradores.
              </p>
            </div>

            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Gestão simplificada para o RH
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                A gestão do plano de sua empresa 100% de forma digital, pelo
                portal exclusivo. Você terá o controle de todas as informações
                sobre o plano da empresa e de seus colaboradores.
              </p>
            </div>

            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Sem dor de cabeça na hora da fatura
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Disponibilizamos uma ferramenta para que você acompanhe a
                evolução da fatura, compare valores, corrija as inconsistências
                e realize o fechamento da fatura da forma mais simples possível.
              </p>
            </div>

            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Menos demandas e mais autonomia
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Com o app Mio, os colaboradores terão acesso às informações
                sobre seu plano coletivo, podendo acompanhar resultados e
                solicitar alterações, sem gerar mais demandas para o RH.
              </p>
            </div>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section2;
