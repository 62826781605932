import { Section } from "components/layout/Section";
import { Background } from "components/atoms/Background";
import { useInView } from "react-spring";
import { useEffect, useState } from "react";
import Animation from "components/atoms/Animation";

const Section1 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Background color="bg-[#314C52] rounded-ee-[100px]">
      <Section>
        <div className="flex flex-col lg:flex-row gap-20 lg:gap-[134px]">
          <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
            <div className="flex flex-col flex-1 gap-4">
              <h5 className="text-2xl font-semibold text-primaryIntense leading-[133%]">
                O que é portabilidade?
              </h5>

              <p className="text-base text-white font-medium leading-[125%]">
                A portabilidade de um plano é uma transferência de recursos de
                previdência que estão aplicados em outra instituição, para o
                Mio. Para isso, basta nos informar alguns dados sobre o plano
                atual e deixar que nossa equipe fará o restante.   
              </p>

              <p className="text-base text-white font-medium leading-[125%]">
                Além de ágil e digital, a portabilidade para o Mio não tem
                custos para você.
              </p>
            </div>
          </Animation>

          <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
            <div className="flex flex-col flex-1 gap-1">
              <h6 className="text-xl font-semibold text-primaryIntense leading-[140%] mt-0">
                Solicite a portabilidade pelo app
              </h6>

              <p className="text-sm text-white leading-[125%]">
                Faça o pedido direto em nosso app. Sem letras complicadas ou
                burocracia.
              </p>

              <h6 className="text-xl font-semibold text-primaryIntense leading-[140%] mt-8">
                Acompanhe o andamento da solicitação
              </h6>

              <p className="text-sm text-white leading-[125%]">
                Verifique a situação atualizada do seu pedido de portabilidade e
                saiba exatamente onde seu dinheiro está e quando ele entrará
                para a sua nova previdência Mio.
              </p>
            </div>
          </Animation>
        </div>
      </Section>
    </Background>
  );
};

export default Section1;
