import { Section } from "components/layout/Section";
import invoice from "assets/images/for-you/invoice.png";
import computer from "assets/images/for-you/computer.png";
import build from "assets/images/for-you/build.png";
import person_add from "assets/images/for-you/person_add.png";
import receipt_long from "assets/images/for-you/receipt_long.png";
import upload_file from "assets/images/for-you/upload_file.png";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section3 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Section>
      <div className="flex flex-col gap-4 lg:gap-24 lg:flex-row">
        <div className="flex flex-col">
          <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
            <div className="flex flex-col items-start max-w-[628px] flex-1">
              <h5 className="mb-4 text-[40px] font-semibold text-primaryTextBase leading-[52px]">
                Previdência do jeito da sua empresa
              </h5>

              <div className="mb-4 text-[18px] text-slate-600 font-medium font-['Inter'] leading-normal">
                Nossos planos podem facilmente se ajustar para atender a
                realidade de sua empresa e te ajudar a alcançar os melhores
                resultados.
              </div>
            </div>
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
            <div className="flex flex-col lg:flex-row gap-20 lg:gap-[24px]">
              <img src={invoice} alt="Retângulo" className="mt-20" />

              <div className="inline-flex flex-col items-start justify-start gap-12 text-left w-100 h-100 ml-27">
                <div className="flex flex-col items-start self-stretch justify-start h-40 gap-4">
                  <img
                    src={computer}
                    alt="Ícone computador"
                    className="h-auto w-1/"
                  />
                  <div className="flex flex-col items-start self-stretch justify-start h-32 gap-2">
                    <div className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-tight">
                      Contratação digital
                    </div>
                    <div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                      O plano escolhido poderá ser contratado com alguns passos.
                      Inclua seus documentos, aguarde a aprovação, assine o
                      contrato e deixe o resto conosco. Faça tudo isso em nosso
                      portal. Sem papéis.
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start self-stretch justify-start h-40 gap-4">
                  <img
                    src={build}
                    alt="Ícone ferramenta"
                    className="h-auto w-1/"
                  />

                  <div className="flex flex-col items-start self-stretch justify-start h-32 gap-2 lg:block">
                    <div className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-tight">
                      Configurador de planos
                    </div>
                    <div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                      Sabemos que cada empresa tem seus próprios objetivos e
                      interesses, por isso, oferecemos um configurador, que
                      permite a escolha as regras do seu plano de previdência.
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start self-stretch justify-start h-32 gap-4">
                  <img
                    src={person_add}
                    alt="Ícone usuário"
                    className="h-auto w-1/"
                  />

                  <div className="flex flex-col items-start self-stretch justify-start h-20 gap-2">
                    <div className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-tight">
                      Onboarding do colaborador
                    </div>
                    <div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                      Oferecemos uma forma digital e intuitiva para seus
                      colaboradores conhecerem as regras do seu plano através do
                      app Mio.{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inline-flex flex-col items-start justify-start gap-12 w-100 h-100">
                <div className="flex flex-col items-start self-stretch justify-start h-40 gap-4">
                  <img
                    src={receipt_long}
                    alt="Ícone recibo"
                    className="h-auto w-1/"
                  />

                  <div className="flex flex-col items-start self-stretch justify-start h-32 gap-2">
                    <div className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-tight">
                      Faturamento simplificado
                    </div>
                    <div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                      Importe sua folha de pagamento e cruze os dados com as
                      informações existentes no portal. Identifique todas as
                      inconsistências, realize os ajustes necessários e emita a
                      fatura de pagamento.{" "}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start self-stretch justify-start h-40 gap-4">
                  <img
                    src={upload_file}
                    alt="Ícone arquivo"
                    className="h-auto w-1/"
                  />

                  <div className="flex flex-col items-start self-stretch justify-start h-32 gap-2">
                    <div className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-tight">
                      Importação da base de colaboradores
                    </div>
                    <div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                      Compartilhe o plano com todos os seus colaboradores de
                      forma automática, importando a lista com os dados pessoais
                      e funcionais diretamente no portal. Se precisar, você
                      também poderá cadastrar cada um.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Animation>
        </div>
      </div>
    </Section>
  );
};

export default Section3;
