import { Section } from "components/layout/Section";
import { Footer } from "components/templates/shared/Footer";
import dashboard from "assets/images/for-you/dashboard.png";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import { Navbar } from "components/templates/shared/Navbar";
import Section1 from "pages/ForCompany/sections/Section1";
import Section2 from "pages/ForCompany/sections/Section2";
import Section3 from "pages/ForCompany/sections/Section3";
import Section4 from "pages/ForCompany/sections/Section4";
import Section5 from "pages/ForCompany/sections/Section5";
import Section6 from "pages/ForCompany/sections/Section6";
import Section7 from "pages/PensionForYou/sections/Section7";
import Animation from "components/atoms/Animation";
import { leftToRight } from "utils/Animation.util";

const ForCompany = () => {
  return (
    <>
      <Navbar isDark />

      <div className="overflow-auto antialiased text-black">
        <Section yPadding="py-0">
          <div className="relative flex">
            <div className="flex flex-col pt-[240px] mb-14 lg:mb-[13rem] lg:pt-[240px]">
              <Animation animationConfig={leftToRight(600)}>
                <h1 className="mb-12 text-[44px] sm:text-[72px] font-semibold font-[Montserrat] text-primaryTextBase whitespace-pre-line leading-[120%]  max-w-[737px] lg:text-[80px]">
                  Previdência <p className="text-primaryIntense">sob medida</p>
                </h1>
              </Animation>

              <Animation animationConfig={leftToRight(800)}>
                <div className="mb-7 lg:mb-10 md:max-w-[737px]">
                  <p className="text-lg text-primaryTextBase">
                    Planos de previdência que se moldam ao seu negócio,{" "}
                    <b>
                      com a solidez e experiência em investimentos da Vinci
                      Partners.
                    </b>
                  </p>
                </div>
              </Animation>

              <Animation animationConfig={leftToRight(1000)}>
                <div>
                  <Link
                    to={"https://corporate.miovincipartners.com/"}
                    target="_blank"
                  >
                    <Button xl>Configure o plano para sua empresa</Button>
                  </Link>
                </div>
              </Animation>
            </div>

            <div className="flex flex-col mt-[10%]">
              <img
                src={dashboard}
                alt="Dashboard"
                className="hidden lg:block"
              />
            </div>
          </div>
        </Section>

        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />

        <Footer />
      </div>
    </>
  );
};

export default ForCompany;
