import { Footer } from "components/templates/shared/Footer";
import { Navbar } from "components/templates/shared/Navbar";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import download from "assets/images/Contents/download.png";
import { Background } from "components/atoms/Background";
import { forwardRef } from "react";
import texts from "./Js/ContentsTexts";
import Section1 from "./Sections/Section1";
import carta from "./Js/Cartas";
import { Section } from "components/layout/Section";
import { leftToRight } from "utils/Animation.util";
import Animation from "components/atoms/Animation";

const Contents = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <>
            <Navbar isDark />
            <div className="overflow-auto antialiased text-black">
                <div className="relative mt-[85px] bg-no-repeat bg-right-top lg:bg-[url('../assets/images/Contents/image20.png')] lg:bg-auto">
                </div>
                <Section1 />
                <Footer />
            </div>
        </>
    );
});
export default Contents;
