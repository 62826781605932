import Animation from "components/atoms/Animation";
import { Background } from "components/atoms/Background";
import { Section } from "components/layout/Section";
import React, { useEffect, useState } from "react";
import { useInView } from "react-spring";

const Benefits = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const topToBottomSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(-150px)",
    config: { duration: 1000 },
  };

  const bottomToUpSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(200px)",
    config: { duration: 1000 },
  };

  return (
    <Background color="bg-[#D6DDFF] rounded-ee-[100px]">
      <Section yPadding="py-20">
        <Animation ref={ref} animationConfig={topToBottomSpring}>
          <div className="flex flex-col gap-4 sm:text-center sm:items-center mb-14">
            <h3 className="text-2xl lg:text-3xl font-semibold text-primaryTextBase max-w-[628px] leading-[120%]">
              Invista no seu futuro sem abrir mão de aproveitar o seu presente
            </h3>

            <p className="text-lg text-primaryTextBase font-medium max-w-[410px] leading-[120%] mb-4">
              Aproveite as vantagens que o seu plano de previdência MIO ofecere.
            </p>
          </div>
        </Animation>

        <Animation ref={ref} animationConfig={bottomToUpSpring}>
          <div className="grid gap-4 mb-16 md:gap-6 justify-items-center md:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col gap-2 p-8 text-sm font-medium bg-primaryLight w-[302px] rounded-lg">
              <h6 className="text-xl font-semibold text-[#5867B0]">
                Menos impostos. Mais dinheiro.
              </h6>

              <p className="text-sm font-medium text-primaryTextBase">
                Benefício fiscal
              </p>

              <p className="my-4 text-xs font-medium text-primaryTextBase">
                Utilize o valor aplicado na previdência para abater da sua base
                de cálculo do IR e, assim, pagar menos impostos.
              </p>
            </div>

            <div className="flex flex-col gap-2 p-8 text-sm font-medium bg-primaryLight w-[302px] rounded-lg">
              <h6 className="text-xl font-semibold text-[#5867B0]">
                O controle nas suas mãos.
              </h6>

              <p className="text-sm font-medium text-primaryTextBase">
                Flexibilidade
              </p>

              <p className="my-4 text-xs font-medium text-primaryTextBase">
                Você escolhe o valor e a frequência das contribuições de acordo
                com a sua disponibilidade financeira.
              </p>
            </div>

            <div className="flex flex-col gap-2 p-8 text-sm font-medium bg-primaryLight w-[302px] rounded-lg">
              <h6 className="text-xl font-semibold text-[#5867B0]">
                Previdência pensada para você.
              </h6>

              <p className="text-sm font-medium text-primaryTextBase">
                Personalização
              </p>

              <p className="my-4 text-xs font-medium text-primaryTextBase">
                Aproveite uma carteira de investimentos diversificada e pensada
                especialmente para você.
              </p>
            </div>
            <div className="flex flex-col gap-2 p-8 text-sm font-medium bg-primaryLight w-[302px] rounded-lg">
              <h6 className="text-xl font-semibold text-[#5867B0]">
                Seu futuro pode render muito.
              </h6>

              <p className="text-sm font-medium text-primaryTextBase">
                Rentabilidade
              </p>

              <p className="my-4 text-xs font-medium text-primaryTextBase">
                Acesso a alíquotas de imposto de renda diferenciadas e ausência
                de come-cotas para aumentar o rendimento.
              </p>
            </div>
          </div>
        </Animation>
      </Section>
    </Background>
  );
};

export default Benefits;
