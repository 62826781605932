import { Section } from "components/layout/Section";
import { Footer } from "components/templates/shared/Footer";
import { Background } from "components/atoms/Background";
import appSimulator from "assets/images/for-you/appSimulator.png";
import PrevidenceItem from "components/organisms/for-you/PrevidenceItem";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import qrCode from "assets/images/qrCode.png";
import assessor from "assets/images/for-you/assessor.png";
import rectangle6 from "assets/images/for-you/rectangle6.png";
import rectangle7 from "assets/images/for-you/rectangle7.png";
import number1 from "assets/images/for-you/number1.png";
import number2 from "assets/images/for-you/number2.png";
import number3 from "assets/images/for-you/number3.png";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import { Navbar } from "components/templates/shared/Navbar";

const CustomMade = () => {
    return (
        <>
            <Navbar isDark />
            <div className="overflow-auto antialiased text-black">
                <Section yPadding="py-0">
                    <div className="relative flex overflow-hidden">
                        <div className="flex flex-col pt-[240px] mb-14 lg:mb-[13rem] lg:pt-[240px]">
                            <h1 className="mb-6 text-[44px] sm:text-[72px] font-semibold font-[Montserrat] text-primaryTextBase whitespace-pre-line leading-[120%]  max-w-[737px] lg:text-[80px]">
                                Planos pensados <span className="text-primaryIntense">para você</span>
                            </h1>

                            <div className="mb-7 lg:mb-12 md:max-w-[628px]">
                                <p className="text-lg text-primaryTextBase">
                                    Nossos planos são personalizados para cada cliente, com recomendações das melhores alocações de investimentos, para <b>alcançar os seus objetivos.</b>
                                </p>
                            </div>

                            <div>
                                <Link to={"/invista"}>
                                    <Button xl>Quero um plano pensado para mim</Button>
                                </Link>
                            </div>
                        </div>

                        <img src={assessor} alt="Assessor" className="absolute hidden right-1 top-36 lg:block" />
                    </div>
                </Section>

                <Background color="bg-[#314C52] rounded-ee-[100px]">
                    <Section>
                        <div className="flex flex-col lg:flex-row gap-20 lg:gap-[200px]">
                            <div className="flex flex-col flex-1 gap-4">
                                <h5 className="text-2xl font-semibold text-primaryIntense leading-[133%]">Nosso Assessor de Previdência</h5>

                                <p className="text-base text-white font-medium leading-[125%]">
                                    Uma ferramenta que emprega o mais alto nível tecnológico, combinado com o poder de análise com diversos cenários e apoiada por um comitê de investimento, para sugerir as melhores recomendações para o seu
                                    plano de previdência.
                                </p>
                            </div>

                            <div className="flex flex-col flex-1 gap-1">
                                <h6 className="text-xl font-semibold text-primaryIntense leading-[140%]">Recomendações únicas</h6>

                                <p className="text-sm text-white leading-[125%]">
                                    Entendemos que cada cliente possui seu próprio objetivo e seu jeito de levar a vida. Por isso, nossos planos recebem recomendações de alocações únicas do nosso Assessor.
                                </p>

                                <h6 className="text-xl font-semibold text-primaryIntense leading-[140%] mt-8">Sempre ao seu lado</h6>

                                <p className="text-sm text-white leading-[125%]">
                                    O Assessor te acompanhará durante toda a sua jornada de investimento, realizando rebalanceamento em sua carteira sempre que algo mudar em sua vida. Isso, para oferecer o produto certo, independente do
                                    momento em que você estiver.
                                </p>
                            </div>
                        </div>
                    </Section>
                </Background>

                <Section>
                    <div className="relative flex flex-col items-center">
                        <h5 className="mb-14 text-3xl font-semibold text-primaryTextBase leading-[120%] text-center max-w-[628px] z-10">Alcance seus objetivos com o plano recomendado pelo nosso Assessor</h5>
                        <div className="flex flex-col max-w-[628px] text-slate-600 text-lg font-normal font-['Inter'] leading-7 mt-10 relative z-10">
                            A recomendação do nosso assessor digital de previdência combina dois tipos de fundos: o Referenciado DI e o Multimercado.
                            <br />
                            <br />
                            O fundo Referenciado DI acompanha a variação do indicador DI (depósito interfinanceiro), que reflete os juros entre os bancos. Este fundo investe em títulos públicos buscando uma rentabilidade próxima ao CDI.
                            <br />
                            <br />
                            Já o fundo Multimercado é mais diversificado. Ele pode investir em ações, títulos públicos, moedas (taxas cambiais) e títulos de renda fixa corporativos, tanto no Brasil como no exterior. O processo de
                            investimento busca aproveitar oportunidades de rendimento superior ao CDI em diferentes mercados e setores.
                            <br />
                            <br />
                            O Multimercado utiliza uma estratégia baseada em análises financeiras e econômicas combinadas a métodos quantitativos estatísticos. Por meio de simulações feitas com técnicas computacionais diversas de cenários
                            futuros que incorporam informações econômicas, financeiras e a opinião dos estrategistas, são calculadas recomendações de investimentos com a maior chance de bom resultado em qualquer cenário. Por fim, um comitê
                            de investimento analisa as recomendações com olhar técnico e criterioso de investimentos e define a melhor. Esta recomendação final é oferecida no fundo Multimercado Vinci Optimum usado pelo nosso assessor
                            digital. O processo incorpora milhares de cenários e suas probabilidades porque acreditamos que o mercado financeiro é imprevisível. Nosso portfólio é estruturado para buscar rentabilidade mesmo diante de eventos
                            inesperados, como a pandemia que ocorreu em 2020.
                            <br />
                            <br />
                            O nosso assessor digital usa as informações que você fornece de quanto dinheiro e quanto tempo deseja poupar, ou quanto de patrimônio deseja acumular, para estimar em cada cenário futuro do fundo Vinci Optimum o
                            seu patrimônio de previdência. Sabendo isso, ele calcula a combinação do Vinci Optimum com Referenciado DI mais seguro para você atingir seu objetivo com a maior chance possível, chegando a carteira ideal para
                            você. A medida que você se aproxima do seu objetivo de previdência ano a ano, o Assessor te acompanha e transfere gradualmente recursos investidos do multimercado para o fundo referenciado DI para preservar o seu
                            patrimônio conquistado.
                            <br />
                            <br />
                            No Mio, oferecemos essa análise técnica totalmente digital e diretamente no aplicativo, para que você possa gerenciar sua previdência de forma segura e reduzir a chance de falta de dinheiro na aposentadoria.
                            Simule, contrate e invista em previdência de um jeito finalmente eficiente.
                        </div>
                        <div className="absolute left-0 top-[-24%] bottom-0 lg:flex hidden items-center">
                            <img src={rectangle6} alt="Retângulo 6" className="h-[528px] w-auto max-w-[200px]" />
                        </div>
                        <div className="absolute right-0 top-0 bottom-[-36%] lg:flex hidden items-center">
                            <img src={rectangle7} alt="Retângulo 7" className="h-[864px] w-auto max-w-[200px]" />
                        </div>
                    </div>
                </Section>

                <Section>
                    <div className="flex flex-col gap-4 lg:gap-24 lg:flex-row">
                        <div className="flex flex-col items-start max-w-[628px] flex-1">
                            <h5 className="mb-4 text-[40px] font-semibold text-primaryTextBase">Simule um plano pensado para você</h5>
                            <p className="text-xl font-semibold text-primaryTextBase mb-14">Baixe nosso app, crie sua conta sem custo e simule.</p>

                            <PrevidenceItem
                                icon={number1}
                                title="Conheça seu perfil de investidor"
                                description="Responda algumas perguntas sobre seu 
                            comportamento frente algumas situações relacionadas a investimentos e conheça o perfil de investidor mais próximo da sua realidade."
                            />

                            <PrevidenceItem
                                icon={number2}
                                title="Simule um plano de acordo com seus objetivos"
                                description="Você poderá simular planos a partir do 
                                valor que deseja pagar ou pensando no seu objetivo final, 
                                poderá indicar o valor que deseja receber e o nosso Assessor 
                                vai recomendar a alocação ideal para você alcançar seu objetivo."
                            />

                            <PrevidenceItem
                                icon={number3}
                                title="Visualize outros cenários projetados"
                                description="Além do plano ideal, considerado para 
                            o seu perfil e objetivo, o Assessor também apresentará outros 
                            dois cenários com alocações diferenciadas por nível de risco. 
                            Dessa forma você poderá comparar com outras projeções caso você optasse por mais ou menos risco."
                            />
                        </div>

                        <div className="flex flex-col">
                            <img src={appSimulator} alt="Simulador apple" />

                            <div className="flex-col text-center items-center gap-4 hidden lg:block">
                                <h4 className="text-base font-semibold text-primaryTextBase">Baixe o app e comece a investir</h4>

                                <div className="flex flex-col lg:flex-row items-center lg:items-start gap-3 lg:gap-7 ml-36 mt-5">
                                    <div className="mt-1">
                                        <a href="https://play.google.com/store/apps/details?id=br.com.vinciprev" target="_blank" rel="noreferrer">
                                            <img src={googlePlayBadge} alt="Google Play" style={{ width: "150px" }} />
                                        </a>
                                        <a href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937" target="_blank" rel="noreferrer">
                                            <img src={appStoreBadge} alt="App Store" className="mt-3" style={{ width: "150px" }} />
                                        </a>
                                    </div>

                                    <div className="ml-3 lg:ml-0 hidden lg:block">
                                        <img src={qrCode} alt="QR Code para download" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4 mb-8 text-center lg:hidden lg:justify-start">
                            <div className="flex flex-col gap-4">
                                <h4 className="text-base font-semibold text-primaryTextBase">Baixe o app e comece a investir</h4>

                                <div className="flex justify-between gap-3 lg:gap-7">
                                    <a href="https://play.google.com/store/apps/details?id=br.com.vinciprev" target="_blank" rel="noreferrer">
                                        <img src={googlePlayBadge} alt="Google Play" />
                                    </a>

                                    <a href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937" target="_blank" rel="noreferrer">
                                        <img src={appStoreBadge} alt="App Store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
                <Footer />
            </div>
        </>
    );
};

export default CustomMade;
