import { Section } from "components/layout/Section";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section4 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const upToDownSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(-150px)",
    config: { duration },
  });

  const downToUpSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(150px)",
    config: { duration },
  });

  return (
    <Section>
      <div className="flex flex-col items-center">
        <Animation ref={refView} animationConfig={upToDownSpring(600)}>
          <h5 className="mb-14 text-3xl font-semibold text-primaryTextBase leading-[120%] text-center max-w-[628px]">
            Aproveite os benefícios que a previdência privada Mio oferece
          </h5>
        </Animation>

        <div className="flex flex-row flex-wrap gap-6">
          <Animation ref={refView} animationConfig={downToUpSpring(1000)}>
            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Menos impostos
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Diferente de outros investimentos, a previdência privada pode te
                ajudar a economizar através do benefício fiscal, que abate os
                valores aportados em até 12% da sua renda tributável anual,
                permitindo que você cuide do seu futuro, sem abrir mão de viver
                o presente. 
              </p>
            </div>
          </Animation>

          <Animation ref={refView} animationConfig={downToUpSpring(1500)}>
            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Sem come-cotas
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Outra vantagem é a ausência de come-cotas, que antecipa a
                cobrança de imposto sobre os fundos de investimento. Dessa forma
                seu dinheiro pode render muito mais.
              </p>
            </div>
          </Animation>

          <Animation ref={refView} animationConfig={downToUpSpring(2000)}>
            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Portabilidade sem custos
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Tenha mais facilidade e tranquilidade para investir com a
                instituição que desejar. Leve sua previdência para qualquer
                instituição, sem a necessidade de pagar taxas ou impostos para
                isso.
              </p>
            </div>
          </Animation>

          <Animation ref={refView} animationConfig={downToUpSpring(2000)}>
            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Facilidade para investir
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Você não precisa realizar aplicações iniciais de valores
                elevados ou pagamentos frequentes para começar. Invista no seu
                plano conforme sua disponibilidade financeira e objetivos e
                realize ajustes nos valores das contribuições sempre que
                desejar.
              </p>
            </div>
          </Animation>

          <Animation ref={refView} animationConfig={downToUpSpring(1500)}>
            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Sucessão descomplicada
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Indique um ou mais beneficiários que receberão os recursos
                acumulados no plano no caso de seu falecimento. Os beneficiários
                terão acesso rápido e simplificado aos recursos acumulados, sem
                a necessidade de passar por inventário.
              </p>
            </div>
          </Animation>

          <Animation ref={refView} animationConfig={downToUpSpring(1000)}>
            <div className="p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <p className="font-medium text-primaryTextLightIntensityBase">
                Isenção de ITCMD
              </p>
              <p className="text-xs text-primaryTextLightIntensityBase">
                Em alguns estados brasileiros, os beneficiários estarão isentos
                de recolher o imposto de transmissão, causa mortis e doação -
                ITCMD, que incide sobre o valor total a receber, e suas
                alíquotas variam atualmente entre 2 a 8%, a depender do estado.
              </p>
            </div>
          </Animation>
        </div>
      </div>
    </Section>
  );
};

export default Section4;
