import { Section } from "components/layout/Section";
import { ReactComponent as IphoneIcon } from "assets/svg/iphone.svg";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import qrCode from "assets/images/qrCode.png";
import { forwardRef, useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";
import { fadeIn } from "utils/Animation.util";

const StartSection =
    forwardRef<
        HTMLDivElement>
        ((props, ref) => (
            <Section yPadding="py-20 lg:py-[140px]">
                <div ref={ref} className="flex flex-col items-center lg:items-start lg:flex-row mb-4 gap-12 lg:gap-[132px]">
                    <div className="flex flex-col lg:max-w-[302px]">
                        <h5 className="text-[40px] font-semibold text-primaryTextBase font-[Montserrat]">Digital. Simples. Sob medida.</h5>

                        <p className="text-lg sm:text-base text-primaryTextBase">Invista em previdência privada de um jeito finalmente eficiente.</p>

                        <div className="justify-center hidden mt-16 lg:flex lg:justify-start">
                            <div className="flex flex-col gap-4">
                                <h4 className="text-base font-semibold text-primaryTextBase">Baixe o app e comece a investir</h4>

                                <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start lg:gap-7">
                                    <div className="mt-1">
                                        <a href="https://play.google.com/store/apps/details?id=br.com.vinciprev" target="_blank" rel="noreferrer">
                                            <img src={googlePlayBadge} alt="Google Play" style={{ width: '150px' }} />
                                        </a>
                                        <a href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937" target="_blank" rel="noreferrer">
                                            <img src={appStoreBadge} alt="App Store" className="mt-3" style={{ width: '150px' }} />
                                        </a>
                                    </div>

                                    <div className="ml-3 lg:ml-0">
                                        <img src={qrCode} alt="QR Code para download" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <IphoneIcon className="h-96 lg:h-auto" />

                        <div className="flex justify-center mt-4 mb-8 text-center lg:hidden lg:justify-start">
                            <div className="flex flex-col gap-4">
                                <h4 className="text-base font-semibold text-primaryTextBase">Baixe o app e comece a investir</h4>

                                <div className="flex justify-between gap-3 lg:gap-7">
                                    <a href="https://play.google.com/store/apps/details?id=br.com.vinciprev" target="_blank" rel="noreferrer">
                                        <img src={googlePlayBadge} alt="Google Play" />
                                    </a>

                                    <a href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937" target="_blank" rel="noreferrer">
                                        <img src={appStoreBadge} alt="App Store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col flex-1 gap-8 lg:pt-16">
                        <h6 className="mb-4 text-xl font-semibold text-primaryTextBase leading-[120%] font-[Montserrat]">Um jeito simples de investir, conectado com o seu jeito de levar a vida.</h6>

                        <div className="flex items-center gap-4">
                            <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                <p className="font-medium">1</p>
                            </div>

                            <div className="flex flex-col flex-1">
                                <p className="text-lg font-medium text-primaryTextBase">Baixe o app e crie sua conta</p>

                                <p className="text-sm sm:text-base text-primaryTextBase">Crie a sua conta com apenas alguns cliques.</p>
                            </div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                <p className="font-medium">2</p>
                            </div>

                            <div className="flex flex-col flex-1">
                                <p className="text-lg font-medium text-primaryTextBase">Descubra seu perfil de investidor</p>

                                <p className="text-sm sm:text-base text-primaryTextBase">Conservador, moderado ou arrojado? Seu perfil aliado ao seu planejamento.</p>
                            </div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                <p className="font-medium">3</p>
                            </div>

                            <div className="flex flex-col flex-1">
                                <p className="text-lg font-medium text-primaryTextBase">Simule o seu plano ideal</p>

                                <p className="text-sm sm:text-base text-primaryTextBase">Informe seus objetivos e receba sugestões de planos pensados para você.</p>
                            </div>
                        </div>

                        <div className="flex items-center gap-4">
                            <div className="flex items-center justify-center rounded-full w-14 h-14 bg-primary">
                                <p className="font-medium">4</p>
                            </div>

                            <div className="flex flex-col flex-1">
                                <p className="text-lg font-medium text-primaryTextBase">Contrate e veja seu dinheiro render</p>

                                <p className="text-sm sm:text-base text-primaryTextBase">Sem taxas escondidas ou letras miúdas. Você no controle do seu futuro.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        ));

export { StartSection };
