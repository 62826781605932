import Animation from "components/atoms/Animation";
import { Button } from "components/atoms/Button";
import { Section } from "components/layout/Section";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-spring";
import { fadeIn } from "utils/Animation.util";

const ImageSection = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const textLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Section yPadding="py-0 px-0">
      <Animation ref={refView} animationConfig={fadeIn(600)}>
        <div className="hidden sm:flex flex-col gap-4 lg:gap-8 relative bg-[url('../assets/images/image-bg.png')] bg-left lg:bg-center bg-no-repeat bg-cover h-96 w-[90%] lg:w-auto lg:h-[549px] px-10 py-10 lg:px-20 lg:pt-20 pb-36 mx-auto lg:mx-0 rounded-3xl">
          <Animation ref={refView} animationConfig={textLeftSpring(600)}>
            <h3 className="text-xl md:text-3xl lg:text-[52px] text-white font-semibold max-w-[500px] leading-[120%] font-[Montserrat]">
              Entenda hoje tudo o que seu dinheiro pode ser amanhã
            </h3>
          </Animation>

          <Animation ref={refView} animationConfig={textLeftSpring(800)}>
            <p className="text-base font-medium text-white">
              Comece a investir hoje e garanta a sua tranquilidade no futuro.
            </p>
          </Animation>

          <Animation ref={refView} animationConfig={textLeftSpring(1000)}>
            <div className="mt-6 lg:mt-0">
              <Link to="/invista">
                <Button xl>Garantir um futuro que rende muito</Button>
              </Link>
            </div>
          </Animation>
        </div>
      </Animation>

      <Animation ref={refView} animationConfig={fadeIn(600)}>
        <div className="block sm:hidden bg-[url('../assets/images/image-bg-mobile.png')] bg-no-repeat bg-cover h-[248px] py-10 px-6">
          <Animation ref={refView} animationConfig={textLeftSpring(600)}>
            <h3 className="text-xl text-white font-semibold max-w-[167px] leading-[120%]">
              Um jeito simples de investir, conectado com o seu jeito de levar a
              vida.
            </h3>
          </Animation>
        </div>
      </Animation>
    </Section>
  );
};

export { ImageSection };
