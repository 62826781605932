// import Link from "next/link";

import { Section } from "components/layout/Section";
import aboutSectionBadge from "assets/svg/aboutSection.svg";
import { Button } from "components/atoms/Button";
import { ReactComponent as ExtraIcon } from "assets/svg/home.svg";
import { ReactComponent as HomeIcon } from "assets/svg/extra.svg";
import { ReactComponent as PeopleIcon } from "assets/svg/people.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const AboutSection = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const springProps = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-150px)",
    config: { duration: 1000 },
  };

  const textSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Section yPadding="py-10 lg:py-[140px]">
      <div className="flex flex-col mb-4 lg:flex-row">
        <h5 className="mb-14 text-xl lg:text-2xl font-semibold text-primaryTextBase leading-[120%] text-left sm:text-center block lg:hidden">
          Aproveite todo o potencial do seu dinheiro e comece a investir agora
          mesmo com o Mio para alcançar seus objetivos
        </h5>

        <div className="flex items-center justify-center flex-1 lg:justify-start">
          <Animation ref={ref} animationConfig={springProps}>
            <img
              className="w-[80%] md:w-[320px] lg:w-[410px] mb-10 lg:mb-0"
              src={aboutSectionBadge}
              alt="Google Play"
            />
          </Animation>
        </div>

        <div className="flex flex-col-reverse items-center flex-1 sm:flex-col pt-7">
          <Animation ref={ref} animationConfig={textSpring(600)}>
            <h5 className="mb-14 text-xl lg:text-2xl font-semibold text-primaryTextBase leading-[120%] hidden lg:block">
              Aproveite todo o potencial do seu dinheiro e comece a investir
              agora mesmo com o Mio para alcançar seus objetivos
            </h5>
          </Animation>

          <Animation ref={ref} animationConfig={textSpring(800)}>
            <div className="flex flex-col items-center gap-6 mt-10 sm:mt-0 sm:mb-12 md:flex-row">
              <div className="flex flex-col w-[193px] items-center gap-2 px-4 py-8 text-center bg-primaryLight">
                <HomeIcon />
                <p className="text-sm font-medium text-primaryTextBase">
                  Garanta sua renda extra após a aposentadoria
                </p>
              </div>

              <div className="flex flex-col w-[193px] items-center gap-2 px-4 py-8 text-center bg-primaryLight">
                <ExtraIcon />
                <p className="text-sm font-medium text-primaryTextBase">
                  Compre a casa que você sempre sonhou
                </p>
              </div>

              <div className="flex flex-col w-[193px] items-center gap-2 px-4 py-8 text-center bg-primaryLight">
                <PeopleIcon />
                <p className="text-sm font-medium text-primaryTextBase">
                  Prepare o planejamento de uma pessoa próxima
                </p>
              </div>
            </div>
          </Animation>

          <Animation ref={ref} animationConfig={textSpring(1000)}>
            <div className="flex flex-col gap-6">
              <p className="text-lg font-medium sm:text-base text-primaryTextBase">
                Nós temos um plano feito para o seu dinheiro crescer no ritmo
                dos seus objetivos.
              </p>

              <Link className="hidden lg:block" to={"/para-voce"}>
                <Button xl>Descubra o seu novo plano de previdência</Button>
              </Link>
            </div>
          </Animation>
        </div>
      </div>
    </Section>
  );
};

export { AboutSection };
