import { Section } from "components/layout/Section";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section4 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Section yPadding="py-0 px-0">
      <div className="hidden sm:flex flex-col gap-4 lg:gap-8 relative bg-[url('../assets/images/for-you/investidor.png')] bg-left lg:bg-center bg-no-repeat bg-cover h-96 w-[90%] lg:w-auto lg:h-[549px] px-10 py-10 lg:px-20 lg:pt-20 pb-36 mx-auto lg:mx-0 rounded-3xl">
        <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
          <h3 className="text-xl md:text-3xl lg:text-[52px] text-white font-semibold max-w-[700px] leading-[100%] font-[Montserrat]">
            Mais simples.
          </h3>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
          <h3 className="text-xl md:text-3xl lg:text-[52px] text-white font-semibold max-w-[700px] leading-[100%] font-[Montserrat]">
            Menos burocrático.
          </h3>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(1000)}>
          <h3 className="text-xl md:text-3xl lg:text-[52px] text-white font-semibold max-w-[700px] leading-[100%] font-[Montserrat]">
            Mais eficiente.
          </h3>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(1200)}>
          <p className="text-base font-medium text-white">
            Nosso portal oferece mais facilidade para o dia-a-dia do RH.
          </p>
        </Animation>

        <Animation ref={ref} animationConfig={leftToRightSpring(1400)}>
          <div className="mt-6 lg:mt-0">
            <Link to="https://corporate.miovincipartners.com/" target="_blank">
              <Button xl>Criar uma conta para minha empresa</Button>
            </Link>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section4;
