import { Section } from "components/layout/Section";
import questions from "assets/images/for-you/questions.png";
import article from "assets/svg/article.svg";
import Collapse from "components/atoms/Collapse";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section9 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Section>
      <div className="flex flex-col gap-6 lg:flex-row">
        <Animation ref={refView} animationConfig={leftToRightSpring(600)}>
          <div className="flex flex-col">
            <h5 className="text-[40px] text-primaryTextBase font-semibold leading-[120%]">
              Sem palavras complicadas
            </h5>

            <p className="text-2xl font-semibold text-primaryTextBase">
              Tire suas dúvidas sobre a previdência Mio
            </p>

            <img src={questions} alt="Questions" className="mt-20" />
          </div>
        </Animation>

        <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
          <div className="flex flex-col flex-1 gap-4">
            <Collapse
              icon={article}
              title="Por que escolher um plano de previdência privada?"
              answer={
                <div>
                  <p className="mb-4">
                    Investir em uma previdência privada é uma ótima forma de
                    planejar financeiramente seu futuro, seja com objetivo de
                    garantir uma renda extra após a aposentadoria, preparar seu
                    planejamento sucessório ou até mesmo para possibilitar o
                    alcance de objetivos com prazos mais curtos (como comprar um
                    imóvel, pagar a faculdade de seu filho etc).
                  </p>
                  <p className="mb-4">
                    Diferente de outros investimentos, a previdência privada
                    pode te ajudar a economizar através do benefício fiscal, que
                    abate os valores aportados em até 12% da sua renda
                    tributável anual, permitindo que você cuide do seu futuro,
                    sem abrir mão de viver o presente.
                  </p>
                </div>
              }
            />

            <Collapse
              icon={article}
              title="Qual o melhor momento para começar a investir em previdência privada?"
              answer={
                <div>
                  <p className="mb-4">
                    Não existe uma idade mínima para se começar a investir em
                    previdência privada. Quanto mais cedo você começar, mais
                    você se beneficiará dos juros compostos que vão resultar em
                    um crescimento mais rápido das suas reservas. Um aspecto
                    muito importante a ser considerado pelo investidor que
                    deseja alcançar seus objetivos nos investimentos em
                    Previdência é a constância das aplicações.
                  </p>
                  <p className="mb-4">
                    Para saber quanto investir mensalmente, baixe o app e um
                    simule um plano feito para você.
                  </p>
                </div>
              }
            />

            <Collapse
              icon={article}
              title="Quais são as formas possíveis para aplicar em meu plano de previdência?"
              answer={
                <div>
                  <p className="mb-4">
                    Uma das muitas vantagens dos planos de previdência privada é
                    a flexibilidade que o investidor tem para fazer seus
                    aportes.
                  </p>
                  <p className="mb-4">
                    A partir da aplicação inicial realizada no momento da adesão
                    ao Plano, o participante poderá optar pela melhor
                    alternativa para seus próximos investimentos, sempre
                    considerando seu objetivo e disponibilidade financeira:
                  </p>
                  <p className="mb-4">
                    <li>
                      Contribuições regulares: O participante pode optar por
                      fazer aplicações mensais ou periódicas ao seu plano de
                      previdência.
                    </li>
                  </p>
                  <p className="mb-4">
                    <li>
                      Contribuições esporádicas: São aplicações pontuais, que
                      podem ser feitas em qualquer momento pelo participante.
                    </li>
                  </p>
                  <p className="mb-4">
                    <li>
                      Portabilidade: O participante pode transferir recursos de
                      outro plano de previdência privada, sem custos, para o
                      PGBL ou VGBL.
                    </li>
                  </p>
                </div>
              }
            />

            <Collapse
              icon={article}
              title="Qual é a frequência ideal para fazer as aplicações?"
              answer={
                <div>
                  <p className="mb-4">
                    Na prática, quanto maior for a frequência das aplicações no
                    plano de previdência, maior será o volume de recursos
                    acumulados no final, uma vez que os valores aplicados
                    estarão expostos às taxas de juros dos investimentos por um
                    prazo maior. Além disso, como não dá para acertar exatamente
                    a melhor hora para investir, aplicações mais frequentes
                    farão com que o investidor tenha um preço médio nos
                    investimentos, reduzindo os impactos de eventuais altas e
                    baixas dos ativos.
                  </p>
                  <p className="mb-4">
                    Em resumo: quanto mais frequentes forem suas alocações no
                    plano de previdência, mais rápido você poderá atingir seu
                    objetivo.
                  </p>
                </div>
              }
            />
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section9;
