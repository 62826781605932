import { AboutSection } from "components/templates/home/About";
import Benefits from "components/templates/home/Benefits";
import { Hero } from "components/templates/home/Hero";
import { ImageSection } from "components/templates/home/ImageSection";
import { InfoSection } from "components/templates/home/InfoSection";
import { StartSection } from "components/templates/home/StartSection";
import { Footer } from "components/templates/shared/Footer";
import { useRef, useEffect } from "react";

const GTM_ID = process.env.REACT_APP_GTM_ID; 

const Homepage = () => {
  const appRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (GTM_ID) {
      const headScript = document.createElement("script");
      headScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `;
      document.head.appendChild(headScript);

      const bodyScript = document.createElement("noscript");
      bodyScript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.insertBefore(bodyScript, document.body.firstChild);
    }
  });

  return (
    <div className="overflow-auto antialiased text-black">
      <Hero />
      <AboutSection />
      <Benefits />
      <StartSection ref={appRef} />
      <ImageSection />
      <InfoSection
        goToSection={() =>
          appRef?.current?.scrollIntoView({ behavior: "smooth" })
        }
      />
      <Footer />
    </div>
  );
};

export default Homepage;
