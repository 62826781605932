import { Link } from "react-router-dom";
import texts from "../Js/ContentsTexts";
import { Section } from "components/layout/Section";
import Animation from "components/atoms/Animation";
import { leftToRight } from "utils/Animation.util";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";


const Section1 = () => {
     const [isSectionVisible, setSectionVisible] = useState(false);
     const [refView, inView] = useInView({ once: true });

     useEffect(() => {
          if (inView) setSectionVisible(true);
     }, [inView]);


     const leftToRightSpring = (duration: number) => ({
          opacity: isSectionVisible ? 1 : 0,
          transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
          config: { duration },
     });


     return (
          <>

               <Section>

                    <Animation ref={refView} animationConfig={leftToRightSpring(600)}>
                         <h1 className="text-primaryIntense whitespace-pre-line leading text-[24px] mb-1 font-medium font-[Montserrat] lg:leading-[54px] ">Mídia</h1>
                    </Animation>
                    <Animation animationConfig={leftToRight(600)}>
                         <div className="flex flex-col lg:flex-row items-center lg:items-baseline justify-around  mb-10 gap-10 mt-2 ">

                              <Link to={texts.card1.link} target="_blank">
                                   <div className="flex flex-col items-start  w-[290px] h-[316px]:">
                                        <div className="bg-[url('../assets/images/Contents/image01.png')] w-[290px] h-[154px] rounded-tr-[8px] rounded-l-[8px] rounded-br-[50px]"></div>
                                        <span className="inline-flex items-center rounded-md bg-[#E6EDF0] px-2 py-1 text-[9.179px] font-medium text-[#1E2224] ring-1 ring-inset ring-[#818B8F] my-4">
                                             Mio na mídia
                                        </span>

                                        <div className="mb-4 ">
                                             <p className="text-[16px] leading-5 text-primaryTextLightIntensityBase">{texts.card1.title}</p>
                                        </div>


                                        <div className="flex-col ">
                                             <p className="text-[12px] leading-5 text-primaryTextLightIntensityBase">{texts.card1.secudary}</p>
                                             <p className="text-[10px] leading-5 text-primaryTextLightIntensityBase">{texts.card1.date}</p>
                                        </div>
                                   </div>
                              </Link>
                              <Link to={texts.card2.link} target="_blank">
                                   <div className="flex flex-col items-start justify-around w-[290px] h-[316px]:">
                                        <div className="bg-[url('../assets/images/Contents/image02.png')] w-[290px] h-[154px] rounded-tr-[8px] rounded-l-[8px] rounded-br-[50px]"></div>
                                        <span className="inline-flex items-center rounded-md bg-[#E6EDF0] px-2 py-1 text-[9.179px] font-medium text-[#1E2224] ring-1 ring-inset ring-[#818B8F] my-4">
                                             Mio na mídia
                                        </span>
                                        <div className="mb-4 ">

                                             <p className="text-[16px] leading-5 text-primaryTextLightIntensityBase">{texts.card2.title}</p>

                                        </div>
                                        <div className="flex-col ">
                                             <p className="text-[12px] leading-5 text-primaryTextLightIntensityBase">{texts.card2.secudary}</p>
                                             <p className="text-[10px] leading-5 text-primaryTextLightIntensityBase">{texts.card2.date}</p>
                                        </div>
                                   </div>
                              </Link>


                              {/* <Link to={texts.card3.link} target="_blank">
            <div className="flex flex-col items-start justify-around w-[290px] h-[316px]:">
             <div className="bg-[url('../assets/images/Contents/image03.png')] w-[290px] h-[154px] rounded-tr-[8px] rounded-l-[8px] rounded-br-[50px]"></div>
             <span className="inline-flex items-center rounded-md bg-[#E6EDF0] px-2 py-1 text-[9.179px] font-medium text-[#1E2224] ring-1 ring-inset ring-[#818B8F] my-4">
                 Mio na mídia
                 </span>
                 <div className="mb-4 ">
                    
                      <p className="text-[16px] leading-5 text-primaryTextLightIntensityBase">{texts.card3.title}</p>
                    
                 </div>
                 
                 <div className="flex-col ">
                      <p className="text-[12px] leading-5 text-primaryTextLightIntensityBase">{texts.card3.secudary}</p>
                      <p className="text-[10px] leading-5 text-primaryTextLightIntensityBase">{texts.card3.date}</p>
                 </div>
            </div>
            </Link> */}
                              {/* <Link to={texts.card4.link} target="_blank">
            <div className="flex flex-col items-start justify-around w-[290px] h-[316px]:">
             <div className="bg-[url('../assets/images/Contents/image04.png')] w-[290px] h-[154px] rounded-tr-[8px] rounded-l-[8px] rounded-br-[50px]"></div>
             <span className="inline-flex items-center rounded-md bg-[#E6EDF0] px-2 py-1 text-[9.179px] font-medium text-[#1E2224] ring-1 ring-inset ring-[#818B8F] my-4">
                 Mio na mídia
                 </span>
                 <div className="mb-4 ">
                      <p className="text-[16px] leading-5 text-primaryTextLightIntensityBase">{texts.card4.title}</p>
                 </div>
                 
                 <div className="flex-col ">
                      <p className="text-[12px] leading-5 text-primaryTextLightIntensityBase">{texts.card4.secudary}</p>
                      <p className="text-[10px] leading-5 text-primaryTextLightIntensityBase">{texts.card4.date}</p>
                 </div>
            </div>
            </Link> */}

                         </div>
                    </Animation>
               </Section>
          </>
     );
}
export default Section1;