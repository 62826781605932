import { Section } from "components/layout/Section";
import PrevidenceItem from "components/organisms/for-you/PrevidenceItem";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import qrCode from "assets/images/qrCode.png";
import iconBag from "assets/images/for-you/iconBag.png";
import iconComputer from "assets/images/for-you/iconComputer.png";
import computerAndPhone from "assets/images/for-you/computerAndPhone.png";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section1 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Section>
      <div 
      ref={ref}
      className="flex flex-col gap-4 lg:gap-24 lg:flex-row"
      >
        <div className="flex flex-col items-start max-w-[628px] flex-1">
          <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
            <h5 className="lg:w-[628px] mb-10 mt-[64px] text-[40px] font-semibold text-primaryTextBase leading-[52px]">
              Vantagens que oferecemos para nossos parceiros
            </h5>
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
            <PrevidenceItem
              icon={iconBag}
              title="Gestão da carteira de clientes centralizada"
              description="Mais facilidade para você cuidar de seus clientes com nosso portal parcerias. 
                                    Todas as informações sobre seus clientes e produtos disponíveis em um só lugar."
            />
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1000)}>
            <PrevidenceItem
              icon={iconBag}
              title="Planos com estrutura multifundos"
              description="Oferecemos mais flexibilidade para você configurar planos cada vez mais 
                                    ajustados às necessidades de seus clientes, com a possibilidade de inclusão de diversos fundos em um mesmo plano."
            />
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1200)}>
            <PrevidenceItem
              icon={iconBag}
              title="Produtos flexíveis"
              description="Receba suporte para desenhar e 
                                implementar soluções exclusivas de previdência para seus clientes."
            />
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(1400)}>
            <PrevidenceItem
              icon={iconComputer}
              title="Jornada 100% digital e integrada"
              description="Sem papéis ou burocracia. Você faz tudo pelo portal e seu cliente pelo app. 
                                    Qualquer operação que você realizar será apresentada na tela do app e seus clientes poderão realizar aprovações de forma segura e rápida."
            />
          </Animation>
        </div>

        <div className="flex flex-col items-center justify-center gap-4">
          <Animation ref={ref} animationConfig={rightToLeftSpring(1000)}>
            <div className="flex flex-col mt-[100px]">
              <img src={computerAndPhone} alt="Computador e celular" />
            </div>
          </Animation>

          <Animation ref={ref} animationConfig={rightToLeftSpring(1200)}>
            <div className="flex-col items-center hidden gap-4 mt-2 text-center lg:block">
              <h4 className="text-base font-semibold text-primaryTextBase">
                Baixe o app e comece a investir
              </h4>

              <div className="flex flex-col items-center gap-3 mt-5 ml-8 lg:flex-row lg:items-start lg:gap-7">
                <div className="mt-1">
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlayBadge}
                      alt="Google Play"
                      style={{ width: "150px" }}
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/br/app/mio-vinci-Section1/id6444894937"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appStoreBadge}
                      alt="App Store"
                      className="mt-3"
                      style={{ width: "150px" }}
                    />
                  </a>
                </div>

                <div className="ml-3 lg:ml-0">
                  <img
                    src={qrCode}
                    alt="QR Code para download"
                    className="hidden lg:block"
                  />
                </div>
              </div>
            </div>
          </Animation>
        </div>
        <div className="flex justify-center mt-4 mb-8 text-center lg:hidden lg:justify-start">
          <div className="flex flex-col gap-4">
            <h4 className="text-base font-semibold text-primaryTextBase">
              Baixe o app e comece a investir
            </h4>

            <div className="flex justify-between gap-3 lg:gap-7">
              <a
                href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googlePlayBadge} alt="Google Play" />
              </a>

              <a
                href="https://apps.apple.com/br/app/mio-vinci-Section1/id6444894937"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appStoreBadge} alt="App Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Section1;
