import About from "pages/About";
import ControlHands from "pages/ControlHands";
import CustomMade from "pages/CustomMade";
import ForCompany from "pages/ForCompany/index";
import Homepage from "pages/Home";
import Invest from "pages/Invest";
import NotFound from "pages/NotFound";
import Partners from "pages/Partners/index";
import Planos from "pages/Plans";
import Portability from "pages/Portability/index";
import SecuringFuture from "pages/SecuringFuture";
import Start from "pages/Start";
import TaxBenefit from "pages/TaxBenefit";
import YourFuture from "pages/YourFuture";
import ExclusionAccount from "pages/ExclusionAccount";
import Calculator from "pages/Calculator";
import PensionForYou from "pages/PensionForYou/index";
import CookiesPolicy from "pages/CookiesPolicy";
import Contents from "pages/Contents/index";

export const router = [
  { path: "/", element: <Homepage /> },
  { path: "/sobre", element: <About /> },
  { path: "/invista", element: <Invest /> },
  { path: "/beneficio-fiscal", element: <TaxBenefit /> },
  { path: "/controle", element: <ControlHands /> },
  { path: "/para-voce", element: <PensionForYou /> },
  { path: "/para-sua-empresa", element: <ForCompany /> },
  { path: "/seu-futuro", element: <YourFuture /> },
  { path: "/garantir-futuro", element: <SecuringFuture /> },
  { path: "/portabilidade", element: <Portability /> },
  { path: "/assessor", element: <CustomMade /> },
  { path: "/calculator", element: <Calculator /> },
  { path: "/conteudos", element: <Contents /> },
  { path: "/parceiros", element: <Partners /> },
  { path: "/comecar", element: <Start /> },
  { path: "/planos", element: <Planos /> },
  { path: "/exclua-sua-conta", element: <ExclusionAccount /> },
  { path: "/politica-cookies", element: <CookiesPolicy /> },

  { path: "/*", element: <NotFound /> },
];
