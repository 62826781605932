import { Section } from "components/layout/Section";
import { ReactComponent as Info1 } from "assets/svg/info1.svg";
import { ReactComponent as Info2 } from "assets/svg/info2.svg";
import { ReactComponent as Info3 } from "assets/svg/info3.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";
import { fadeIn } from "utils/Animation.util";

const InfoSection = ({ goToSection }: any) => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const textLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  const textRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Section yPadding="py-20 lg:py-[140px]">
      <div className="flex flex-col gap-20 px-4 sm:px-10 lg:mb-16 lg:flex-row lg:px-0 lg:justify-around">
        <Animation ref={refView} animationConfig={textLeftSpring(600)}>
          <div className="flex flex-col items-center flex-1 gap-6 text-sm font-medium text-center">
            <Info1 />
            <h5 className="text-xl font-semibold text-primaryTextBase">
              Portabilidade descomplicada
            </h5>
            <p className="leading-6 text-primaryTextBase sm:max-w-[302px]">
              Informe os dados de previdência atual, selecione o plano MIO para
              onde deseja transferir os valores, e deixe o resto com a gente.
            </p>

            <Link
              to="/portabilidade"
              className="underline text-primaryTextLight"
            >
              Como funciona
            </Link>
          </div>
        </Animation>

        <Animation ref={refView} animationConfig={fadeIn(600)}>
          <div className="flex flex-col items-center flex-1 gap-6 text-sm font-medium text-center">
            <Info2 />
            <h5 className="text-xl font-semibold text-primaryTextBase">
              Feito sob medida
            </h5>
            <p className="leading-6 text-primaryTextBase max-w-[302px]">
              Um Assessor de Previdência Digital que analisa e projeta milhares
              de cenários de rentabilidade indicando a melhor aplicação de
              investimentos.
            </p>

            <Link to="/assessor" className="underline text-primaryTextLight">
              Conhecer o Assessor
            </Link>
          </div>
        </Animation>

        <Animation ref={refView} animationConfig={textRightSpring(600)}>
          <div className="flex flex-col items-center flex-1 gap-6 text-sm font-medium text-center">
            <Info3 />
            <h5 className="text-xl font-semibold text-primaryTextBase">
              Digital e sem burocracias
            </h5>
            <p className="leading-6 text-primaryTextBase max-w-[302px]">
              Acompanhe seu investimento, realize aplicações, altere os valores
              e frequência de suas contribuições. Sem burocracias e 100%
              Digital.
            </p>

            <p
              className="underline cursor-pointer text-primaryTextLight"
              onClick={goToSection}
            >
              Baixar o App
            </p>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export { InfoSection };
