import { Section } from "components/layout/Section";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section8 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const upToDownSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(-150px)",
    config: { duration },
  });

  const downToUpSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(200px)",
    config: { duration },
  });

  return (
    <Section>
      <div className="flex flex-col gap-14">
        <Animation ref={refView} animationConfig={upToDownSpring(600)}>
          <h5 className="text-[44px] text-primaryTextBase font-semibold leading-[120%] text-center">
            Traga sua previdência para o Mio em 3 passos
          </h5>
        </Animation>

        <Animation ref={refView} animationConfig={downToUpSpring(600)}>
          <div className="flex flex-row flex-wrap gap-6">
            <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                <p className="font-medium text-white">1</p>
              </div>

              <p className="font-medium text-purple">Contrate um plano Mio</p>

              <p className="text-xs text-primaryTextBase">
                Escolha um plano ideal para você, selecione o mesmo tipo de
                plano (PGBL ou VGBL) que você possui na outra instituição e
                realize a contratação no app do Mio.
              </p>
            </div>

            <div className="flex flex-row flex-wrap gap-6">
              <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                  <p className="font-medium text-white">2</p>
                </div>

                <p className="font-medium text-purple">Informe os dados</p>

                <p className="text-xs text-primaryTextBase">
                  No momento da contratação do novo plano, você poderá solicitar
                  a portabilidade. Para isso, basta informar os dados do seu
                  plano atual e informar o valor que deseja portar.
                </p>
              </div>
            </div>

            <div className="flex flex-row flex-wrap gap-6">
              <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                  <p className="font-medium text-white">3</p>
                </div>

                <p className="font-medium text-purple">Aguarde o processo</p>

                <p className="text-xs text-primaryTextBase">
                  O restante fica por nossa conta. Entraremos em contato direto
                  com a instituição onde sua previdência está, e procederemos o
                  pedido de portabilidade para o Mio.
                </p>
              </div>
            </div>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section8;
