import { Section } from "components/layout/Section";
import { Background } from "components/atoms/Background";
import leoImage from "assets/svg/leo-image.svg";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";
import { fadeIn } from "utils/Animation.util";

const Section7 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  const downToUpSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(200px)",
    config: { duration },
  });

  return (
    <Animation ref={refView} animationConfig={fadeIn(600)}>
      <Section>
        <Background color="bg-[#5867B0] rounded-md  rounded-ee-[100px]">
          <div className="flex flex-col lg:flex-row gap-14 px-10 lg:px-20 py-10 lg:max-h-[320px] lg:overflow-visible">
            <div className="flex flex-col max-w-[628px]">
              <Animation ref={refView} animationConfig={leftToRightSpring(600)}>
                <h5 className="text-[28px] text-white font-semibold leading-[120%] mb-4">
                  Entenda como conseguimos sugerir os melhores planos para o seu
                  objetivo
                </h5>
              </Animation>
              <Animation ref={refView} animationConfig={downToUpSpring(600)}>
                <img
                  className="block py-6 lg:hidden"
                  src={leoImage}
                  alt="Leo"
                />
              </Animation>

              <Animation ref={refView} animationConfig={leftToRightSpring(800)}>
                <p className="mb-8 text-base text-white">
                  Uma ferramenta que emprega o mais alto nível tecnológico,
                  combinado com o poder de análise com diversos cenários e
                  apoiada por um comitê de investimento, para sugerir as
                  melhores recomendações para o seu plano de previdência.
                </p>
              </Animation>

              <Animation
                ref={refView}
                animationConfig={leftToRightSpring(1000)}
              >
                <Link to="/assessor">
                  <Button xl>Conheça nosso Assessor de Previdência</Button>
                </Link>
              </Animation>
            </div>

            <div className="hidden lg:-mt-28 lg:block">
              <img src={leoImage} alt="Leo" />
            </div>
          </div>
        </Background>
      </Section>
    </Animation>
  );
};

export default Section7;
