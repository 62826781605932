import Animation from "components/atoms/Animation";
import { Section } from "components/layout/Section";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";

const Section2 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const topToBottomSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(-150px)",
    config: { duration: 1000 },
  };

  const bottomToUpSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(200px)",
    config: { duration: 1000 },
  };
  return (
    <Section>
      <div className="flex flex-col gap-14">
        <Animation ref={ref} animationConfig={topToBottomSpring}>
          <h5 className="text-[44px] text-primaryTextBase font-semibold leading-[120%] text-center">
            3 passos para virar um parceiro Mio
          </h5>
        </Animation>

        <Animation ref={ref} animationConfig={bottomToUpSpring}>
          <div className="flex flex-row flex-wrap gap-6">
            <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                <p className="font-medium text-white">1</p>
              </div>

              <p className="font-medium text-purple">Crie sua conta</p>

              <p className="text-xs text-primaryTextBase">
                Preencha seus dados e faça seu cadastro em nosso portal. Com
                alguns cliques sua conta estará criada.
              </p>
            </div>

            <div className="flex flex-row flex-wrap gap-6">
              <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                  <p className="font-medium text-white">2</p>
                </div>

                <p className="font-medium text-purple">Envie a documentação</p>

                <p className="text-xs text-primaryTextBase">
                  Envie a documentação para nosso e-mail. Vamos analisar as
                  informações e preparar uma proposta para você.
                </p>
              </div>
            </div>

            <div className="flex flex-row flex-wrap gap-6">
              <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                  <p className="font-medium text-white">3</p>
                </div>

                <p className="font-medium text-purple">Assine o contrato</p>

                <p className="text-xs text-primaryTextBase">
                  Depois de assinar o contrato, vamos preparar um portal
                  exclusivo para você começar a oferecer nossos produtos.
                </p>
              </div>
            </div>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section2;
