  export const fadeIn = (duration = 1000) => ({
  opacity: 1,
  from: { opacity: 0 },
  config: { duration },
});

export const leftToRight = (duration = 1000) => ({
  opacity: 1,
  marginLeft: 0,
    from: { opacity: 0, marginLeft: -200 },
  config: { duration },
});

export const rightToLeft = (duration = 1000) => ({
  opacity: 1,
  marginRight: 0,
  from: { opacity: 0, marginRight: 200 },
  config: { duration },
});
