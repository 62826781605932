import { Section } from "components/layout/Section";
import { Footer } from "components/templates/shared/Footer";
import portabilidade from "assets/images/portabilidade.png";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import { Navbar } from "components/templates/shared/Navbar";
import Section4 from "pages/Portability/sections/Section4";
import Section3 from "pages/Portability/sections/Section3";
import Section2 from "pages/Portability/sections/Section2";
import Section1 from "pages/Portability/sections/Section1";
import { fadeIn, leftToRight } from "utils/Animation.util";
import Animation from "components/atoms/Animation";

const Portability = () => {
  return (
    <>
      <Navbar isDark />

      <div className="overflow-auto antialiased text-black">
        <Section yPadding="py-0">
          <div className="relative flex">
            <div className="flex flex-col pt-[240px] mb-14 lg:mb-[13rem] lg:pt-[240px]">
              <Animation animationConfig={leftToRight(600)}>
                <h1 className="mb-6 text-[44px] sm:text-[72px] font-semibold font-[Montserrat] text-primaryTextBase whitespace-pre-line leading-[120%]  max-w-[737px] lg:text-[80px]">
                  Portabilidade{" "}
                  <span className="text-primaryIntense">descomplicada</span>
                </h1>
              </Animation>

              <Animation animationConfig={leftToRight(800)}>
                <div className="mb-7 lg:mb-10 md:max-w-[737px]">
                  <p className="text-lg text-primaryTextBase">
                    Traga sua previdência de outra instituição para o Mio e
                    tenha mais tranquilidade para planejar o seu futuro{" "}
                    <b>com um plano pensado para você.</b>
                  </p>
                </div>
              </Animation>

              <Animation animationConfig={leftToRight(1000)}>
                <div>
                  <Link to="/invista">
                    <Button xl>Solicitar portabilidade para o Mio</Button>
                  </Link>
                </div>
              </Animation>
            </div>

            <Animation animationConfig={fadeIn(1000)}>
              <div className="flex flex-col mt-[5%] hidde lg:block ">
                <img
                  src={portabilidade}
                  alt="Mulher realizando a portabilidade"
                  className="h-[auto] w-[60vh] "
                />
              </div>
            </Animation>
          </div>
        </Section>

        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />

        <Footer />
      </div>
    </>
  );
};

export default Portability;
