import className from "classnames";
import styles from "./styles.module.css";

type IButtonProps = {
  xl?: boolean;
  small?: boolean;
  link?: boolean;
  children: any;
  onClick?: () => void;

  type?: "primary" | "secondary";
  rightIcon?: any;
};

const Button = (props: IButtonProps) => {
  const { type = "primary" } = props;

  const btnClass = className({
    [styles.btn]: true,
    [styles["btn-base"]]: !props.xl,
    [styles["btn-xl"]]: props.xl,
    [styles["btn-small"]]: props.small,
    [styles[`btn-${type}`]]: true,
    [styles["btn-link"]]: props.link,
  });

  return (
    <button className={btnClass} onClick={props.onClick}>
      {props.children}
      {props.rightIcon}
    </button>
  );
};

export { Button };
