import React from "react";
import { Section } from "components/layout/Section";
import constructionImage from "assets/images/in-construction.png";

const InConstruction = () => {
  return (
    <div className="overflow-auto antialiased text-black">
      <Section yPadding="pt-0 pb-20">
        <h5 className="mb-10 text-3xl font-semibold text-center">
          Em construção.
        </h5>
        <div className="flex justify-center">
          <img
            src={constructionImage}
            alt="Em construção"
            className="lg:w-[500px]"
          />
        </div>
      </Section>
    </div>
  );
};

export default InConstruction;
