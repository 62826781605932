import React from "react";
import { Footer } from "components/templates/shared/Footer";
import HeroPages from "components/organisms/HeroPages";
import InConstruction from "pages/InConstruction";

const Start = () => {
  return (
    <div className="overflow-auto antialiased text-black">
      <HeroPages title="Começar" />
      <InConstruction />
      <Footer />
    </div>
  );
};

export default Start;
