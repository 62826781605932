import Animation from "components/atoms/Animation";
import { Section } from "components/layout/Section";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";

const Section5 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const topToBottomSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(-150px)",
    config: { duration: 1000 },
  };

  const bottomToUpSpring = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateY(0)" : "translateY(200px)",
    config: { duration: 1000 },
  };

  return (
    <Section>
      <div className="flex flex-col gap-14">
        <Animation ref={ref} animationConfig={topToBottomSpring}>
          <h5 className="text-[44px] text-primaryTextBase font-semibold leading-[120%] text-center">
            Configure o plano da sua empresa em 3 passos
          </h5>
        </Animation>

        <Animation ref={ref} animationConfig={bottomToUpSpring}>
          <div className="flex flex-row flex-wrap gap-6">
            <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                <p className="font-medium text-white">1</p>
              </div>

              <p className="text-[20px] text-purple">Crie sua conta</p>

              <p className="text-xs text-primaryTextBase">
                Faça o seu cadastro em nosso portal para ter acesso ao
                configurador de planos. Com alguns cliques sua conta estará
                criada e você já poderá começar a criar seus planos. Tudo isso
                sem custo algum.
              </p>
            </div>

            <div className="flex flex-row flex-wrap gap-6">
              <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                  <p className="font-medium text-white">2</p>
                </div>

                <p className="text-[20px] text-purple">
                  Escolha as regras do plano
                </p>

                <p className="text-xs text-primaryTextBase">
                  Navegue pelo nosso configurador de planos para entender e
                  escolher as regras que farão parte do seu plano de
                  previdência. Aqui as regras se ajustam à sua empresa e não o
                  contrário. Você pode configurador quantos planos quiser.
                </p>
              </div>
            </div>

            <div className="flex flex-row flex-wrap gap-6">
              <div className="flex flex-col gap-4 p-8 bg-[#F5F9FA] w-full lg:w-[410px]">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-purple">
                  <p className="font-medium text-white">3</p>
                </div>

                <p className="text-[20px] text-purple">Contrate o plano</p>

                <p className="text-xs text-primaryTextBase">
                  Gostou do plano configurado. Basta seguir com a contratação e
                  enviar a documentação da sua empresa por meio do próprio
                  portal. Você conseguirá acompanhar todo o processo até a
                  implantação do plano utilizando nosso portal.
                </p>
              </div>
            </div>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section5;
