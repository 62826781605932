import { Section } from "components/layout/Section";
import { Background } from "components/atoms/Background";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section1 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Background color="bg-[#314C52] rounded-ee-[100px]">
      <Section>
        <div className="flex flex-col lg:flex-row gap-20 lg:gap-[134px]">
          <div className="flex flex-col flex-1 gap-4">
            <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
              <h5 className="text-3xl font-semibold text-primary leading-[133%]">
                Gestão de benefícios facilitada para o seu RH
              </h5>

              <p className="text-base text-white font-medium leading-[125%]">
                Para oferecer o melhor produto de previdência para os seus
                colaboradores, desenvolvemos o Portal Corporate, que traz mais
                facilidade para o dia a dia do seu RH na gestão do plano de
                previdência da sua empresa.
              </p>
            </Animation>
          </div>

          <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
            <div className="flex flex-col flex-1 gap-1">
              <h6 className="text-base font-semibold text-primary leading-[140%]">
                Sem burocracias ou papéis. Somos 100% digitais
              </h6>

              <p className="text-sm text-white leading-[125%]">
                Acesse nosso portal e tenha o controle do plano da sua empresa
                em mãos.
              </p>

              <h6 className="text-base font-semibold text-primary leading-[140%] mt-8">
                Planos pensados para atender sua empresa e colaboradores
              </h6>

              <p className="text-sm text-white leading-[125%]">
                Configure as regras do seu plano antes de contratar.
              </p>

              <h6 className="text-base font-semibold text-primary leading-[140%] mt-8">
                Portal e aplicativo totalmente integrados.
              </h6>

              <p className="text-sm text-white leading-[125%]">
                Mais autonomia para os colaboradores. Menos demandas para o RH.
              </p>
            </div>
          </Animation>
        </div>
      </Section>
    </Background>
  );
};

export default Section1;
