import { Section } from "components/layout/Section";
import { forwardRef } from "react";
import { Navbar } from "components/templates/shared/Navbar";
import { Footer } from "components/templates/shared/Footer";

const Invest =
    forwardRef<
        HTMLDivElement>
        ((props, ref) => {
            return (
                <>
                    <Navbar isDark />

                    <Section yPadding="py-20 lg:py-[140px]">
                        <div className="flex flex-col items-center lg:items-center">
                            <h5 className="mb-10 text-center lg:text-center text-[40px] font-semibold text-primaryTextBase font-[Montserrat] leading-[60px] mt-10">Privacidade & Termos de Uso</h5>
                            <div ref={ref} className="text-center lg:text-left">
                                <div className="flex items-center justify-center lg:justify-start">
                                    <p className="text-justify sm:text-base text-primaryTextBase">
                                        Os usuários devem ler atentamente o presente Termo de Uso antes de aceitar prosseguir. O simples acesso implica na aceitação automática e integral dos termos e condições estabelecidas no presente Termo e quaisquer alterações que podem ocorrer no futuro.<br /><br />

                                        Caso não concorde com os Termo de Uso do nosso site, é necessário interromper seu uso, sob pena de aceitar integralmente o Termo e condições descritas abaixo.<br /><br />

                                        As informações descritas neste site dizem respeito às empresas que compõem o grupo controlado pela Vinci Vida e Previdência SA. ("Vinci Vida e Previdência") e seus respectivos serviços. A Vinci Vida e Previdência não se responsabiliza por erros, omissões ou imprecisões no conteúdo das informações divulgadas. As informações divulgadas neste site têm caráter meramente informativo e não devem ser consideradas como oferta, solicitação ou tentativa de convencê-lo a comprar ou vender quaisquer planos de previdência geridos pelas empresas da Vinci Vida e Previdência, ou qualquer tipo de recomendação ou orientação de compra ou venda de ativos. A Vinci Vida e Previdência reserva-se o direito de, discricionariamente e sem qualquer obrigação ou notificação, efetuar melhorias ou corrigir qualquer erro ou omissão em qualquer parte deste site, bem como manter informações desatualizadas.<br /><br />

                                        Utilizamos o arquivamento por meio de cookies para controlar o idioma do nosso website.<br /><br />

                                        Como empresa especializada em seguros e previdência, a Vinci Vida e Previdência é regulada pela SUSEP, podendo tratar seus dados pessoais para prestar serviços, se comunicar com você e outras hipóteses permitidas pela aplicabilidade do disposto na Lei Geral de Proteção de Dados Pessoais (Lei n° 13.709) e demais normas aplicáveis.<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Section>
                    <Footer />
                </>
            );
        });

export default Invest;
