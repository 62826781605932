import { Button } from "components/atoms/Button";
import React, { useState } from "react";

const CookieConsentBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(
    localStorage.getItem("@mio/acceptedCookies") === "true"
  );

  const handleAcceptCookies = () => {
    setAcceptedCookies(true);
    localStorage.setItem("@mio/acceptedCookies", "true");
  };

  return (
    <div
      className={`fixed bottom-2 left-2 right-2 py-2 px-4 bg-white text-[#0F1B20]  mx-auto border-solid border border-[#D5D9DA] rounded-lg ${
        acceptedCookies ? "hidden" : ""
      }`}
    >
      <div className="flex items-center justify-between">
        <p className="text-xs">
          Clicando em "Aceito todos os cookies", você aceita nossa política de
          cookies com objetivo de melhorar sua navegação. Conheça nossa política
          e{" "}
          <a className="underline" href="/politica-cookies">
            <b>configure seus cookies</b>
          </a>
          .
        </p>

        <Button small onClick={handleAcceptCookies}>
          aceitar todos os cookies
        </Button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
