import { Section } from "components/layout/Section";
import PrevidenceItem from "components/organisms/for-you/PrevidenceItem";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import qrCode from "assets/images/qrCode.png";
import iconPill1 from "assets/images/for-you/iconPill1.png";
import iconPill2 from "assets/images/for-you/iconPill2.png";
import iconPill3 from "assets/images/for-you/iconPill3.png";
import iconPill4 from "assets/images/for-you/iconPill4.png";
import lace from "assets/images/for-you/lace.png";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section6 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Section>
      <div className="flex flex-col gap-4 lg:gap-24 lg:flex-row">
        <div className="flex flex-col items-start max-w-[628px] flex-1">
          <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
            <h5 className="mb-4 text-[40px] font-semibold text-primaryTextBase">
              Autonomia e controle para os colaboradores
            </h5>
            <p className="text-xl font-semibold text-primaryTextBase mb-14">
              Oferecemos uma experiência integrada, onde seus colaboradores
              terão acesso a todas as informações sobre o plano, direto no app.
            </p>
          </Animation>

          <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
            <PrevidenceItem
              icon={iconPill1}
              title="Onboarding simplificado"
              description="Plano explicado de forma intuitiva para seus colaboradores. 
                As regras são apresentadas de forma clara e direta, reduzindo a necessidade de explicações do RH."
            />
          </Animation>
          <Animation ref={ref} animationConfig={leftToRightSpring(1000)}>
            <PrevidenceItem
              icon={iconPill2}
              title="Descubra o seu plano ideal"
              description="Os colaboradores poderão realizar simulações 
                para entender melhor como este benefício poderá ajudá-los a conquistar seus objetivos para o futuro."
            />
          </Animation>
          <Animation ref={ref} animationConfig={leftToRightSpring(1200)}>
            <PrevidenceItem
              icon={iconPill3}
              title="Contratação sem burocracia"
              description="Chega de papeis e formulários extensos. 
                A contratação do plano pelo colaborador se dá pelo próprio app, utilizando recursos de biometria que garantem a autenticidade."
            />
          </Animation>
          <Animation ref={ref} animationConfig={leftToRightSpring(1400)}>
            <PrevidenceItem
              icon={iconPill4}
              title="Controle do plano"
              description="Os colaboradores não precisarão mais demandar informações ou alterações em seus planos para o RH. 
                No app eles terão mais controle e autonomia para cuidar do plano."
            />
          </Animation>
        </div>

        <div className="flex flex-col items-center justify-center gap-4">
          <Animation ref={ref} animationConfig={rightToLeftSpring(800)}>
            <img src={lace} alt="Frame com as rendas" />
          </Animation>

          <Animation ref={ref} animationConfig={rightToLeftSpring(1000)}>
            <div className="flex-col items-center hidden gap-4 text-center lg:block">
              <h4 className="text-base font-semibold text-primaryTextBase">
                Baixe o app e comece a investir
              </h4>

              <div className="flex flex-col items-center gap-3 mt-5 ml-6 lg:flex-row lg:items-start lg:gap-7">
                <div className="mt-1">
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlayBadge}
                      alt="Google Play"
                      style={{ width: "150px" }}
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appStoreBadge}
                      alt="App Store"
                      className="mt-3"
                      style={{ width: "150px" }}
                    />
                  </a>
                </div>

                <div className="hidden ml-3 lg:ml-0 lg:block">
                  <img src={qrCode} alt="QR Code para download" />
                </div>
              </div>
            </div>
          </Animation>
        </div>

        <Animation ref={ref} animationConfig={rightToLeftSpring(800)}>
          <div className="flex justify-center mt-4 mb-8 text-center lg:hidden lg:justify-start">
            <div className="flex flex-col gap-4">
              <h4 className="text-base font-semibold text-primaryTextBase">
                Baixe o app e comece a investir
              </h4>

              <div className="flex justify-between gap-3 lg:gap-7">
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlayBadge} alt="Google Play" />
                </a>

                <a
                  href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appStoreBadge} alt="App Store" />
                </a>
              </div>
            </div>
          </div>
        </Animation>
      </div>
    </Section>
  );
};

export default Section6;
