
const texts = {
  card1: {
    link: "https://neofeed.com.br/wealth-management/blackrock-maior-gestora-do-mundo-estreia-em-previdencia-no-brasil/",
    title: "Parceria Mio Vinci Partners e BlackRock",
    secudary: "BlackRock",
    date: "08 de Outubro de 2024"
  },
  card2: {
    link: "https://valor.globo.com/financas/noticia/2024/09/25/mio-insurtech-da-vinci-partners-avanca-entre-empresas-medias.ghtml",
    title: "Avanço de mercado Mio Vinci Partners",
    secudary: "Mercado Mio Vinci Partners",
    date: "25 de Setembro de 2024"
  },
  // card3: {
  //   link: "https://braziljournal.com/vinci-cria-seguradora-para-competir-na-previdencia-privada/#:~:text=Vinicius%20Albernaz%2C%20o%20ex%2DCEO,empresas%20e%20reguladores%20do%20setor.",
  //   title: "Vinci cria seguradora para competir na previdência privada",
  //   secudary: "Brazil Journal",
  //   date: "26 de janeiro de 2024"
  // },
  // card4: {
  //   link: "https://tiinside.com.br/28/03/2024/mio-vinci-partners-escolhe-pluggy-como-parceira-de-open-finance/",
  //   title: "Mio Vinci Partners escolhe Pluggy como parceira de open finance",
  //   secudary: "Ti Inside",
  //   date: "14 de julho de 2023"
  // }
};

export default texts;
