import { Footer } from "components/templates/shared/Footer";
import { Navbar } from "components/templates/shared/Navbar";
import Section1 from "pages/PensionForYou/sections/Section1";
import Section2 from "pages/PensionForYou/sections/Section2";
import Section3 from "pages/PensionForYou/sections/Section3";
import Section4 from "pages/PensionForYou/sections/Section4";
import Section5 from "pages/PensionForYou/sections/Section5";
import Section6 from "pages/PensionForYou/sections/Section6";
import Section7 from "pages/PensionForYou/sections/Section7";
import Section8 from "pages/PensionForYou/sections/Section8";
import Section9 from "pages/PensionForYou/sections/Section9";

const PensionForYou = () => {
  return (
    <>
      <Navbar isDark />

      <div className="overflow-auto antialiased text-black">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />

        <Footer />
      </div>
    </>
  );
};

export default PensionForYou;
