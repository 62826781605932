import { Section } from "components/layout/Section";
import result from "assets/images/for-you/result.png";
import computer from "assets/images/for-you/computer.png";
import build from "assets/images/for-you/build.png";
import person_add from "assets/images/for-you/personAdd.png";
import receipt_long from "assets/images/for-you/receiptLong.png";
import upload_file from "assets/images/for-you/uploadFile.png";
import girl from "assets/images/for-you/girl.png";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section3 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [ref, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });

  return (
    <Section>
      <div className="flex flex-col gap-4 lg:gap-24 lg:flex-row">
        <div className="flex flex-col items-start max-w-[628px]">
          <Animation ref={ref} animationConfig={leftToRightSpring(600)}>
            <h5 className="mb-4 text-[40px] font-semibold text-primaryTextBase leading-[52px]">
              Tecnologia pensada para facilitar o seu dia
            </h5>
          </Animation>
          <Animation ref={ref} animationConfig={leftToRightSpring(800)}>
            <img
              src={result}
              alt="Resultado"
              className="mt-20 w-[600px] h-auto"
            />
          </Animation>
        </div>

        <div className="flex flex-col lg:flex-row gap-20 lg:gap-[24px] basis-2/3">
          <Animation ref={ref} animationConfig={rightToLeftSpring(800)}>
            <div className="flex-row items-start justify-start gap-4 text-left w-100 h-100 ml-27">
              <div className="inline-flex items-start self-stretch justify-start gap-2 mb-6 ml-10">
                <div className="flex flex-col items-start self-stretch justify-start w-1/2 gap-2">
                  <img
                    src={computer}
                    alt="Ícone computador"
                    className="h-auto w-1/"
                  />
                  <h3 className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-[16px]">
                    Gestão de clientes
                  </h3>
                  <p className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                    Cadastre seus clientes, consulte e atualize os dados sobre
                    os planos contratados, visualize o histórico de alterações e
                    tenha acesso à principais informações.
                  </p>
                </div>

                <div className="flex flex-col items-start self-stretch justify-start w-1/2 gap-2">
                  <img
                    src={receipt_long}
                    alt="Ícone receita"
                    className="h-auto w-1/"
                  />
                  <h3 className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-[16px]">
                    Acompanhamento de movimentações
                  </h3>
                  <p className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                    Acompanhe em tempo real a situação das propostas enviadas
                    para os clientes, os pagamentos em aberto e os pedidos de
                    portabilidade em andamento.
                  </p>
                </div>
              </div>

              <div className="inline-flex items-start self-stretch justify-start gap-4 mb-6 ml-10">
                <div className="flex flex-col items-start self-stretch justify-start w-1/2 gap-2">
                  <img
                    src={build}
                    alt="Ícone ferramenta"
                    className="h-auto w-1/"
                  />
                  <h3 className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-[16px]">
                    Simulação de planos
                  </h3>
                  <p className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                    Utilize nosso simulador para receber sugestões de planos de
                    nosso Assessor de Previdência para seus clientes.
                  </p>
                </div>
                <div className="flex flex-col items-start self-stretch justify-start w-1/2 gap-2">
                  <img
                    src={upload_file}
                    alt="Ícone upload de arquivo"
                    className="h-auto w-1/"
                  />
                  <h3 className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-[16px]">
                    Dashboard
                  </h3>
                  <p className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                    Os principais dados apresentados de forma clara, para você
                    acompanhar seus resultados e assim, montar as melhores
                    estratégias.
                  </p>
                </div>
              </div>
              <div className="inline-flex items-start self-stretch justify-start gap-4 ml-10">
                <div className="flex flex-col items-start self-stretch justify-start w-1/2 gap-2">
                  <img
                    src={person_add}
                    alt="Ícone adicionar usuário"
                    className="h-auto w-1/"
                  />
                  <h3 className="self-stretch text-slate-600 text-base font-medium font-['Inter'] leading-[16px]">
                    Operações no plano
                  </h3>
                  <p className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                    Emita ordens de pagamento de aplicações extras, solicite a
                    portabilidade de planos de outras instituições e realize
                    alterações na carteira a pedido de seus clientes.
                  </p>
                </div>
                <img src={girl} alt="Mulher" className="w-1/2 h-auto" />
              </div>
            </div>
          </Animation>
        </div>
      </div>
    </Section>
  );
};

export default Section3;
