import { Section } from "components/layout/Section";
import { Background } from "components/atoms/Background";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";

const Section2 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const rightToLeftSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(200px)",
    config: { duration },
  });
  return (
    <Background color="bg-[#314C52] rounded-ee-[100px]">
      <Section>
        <div className="flex flex-col lg:flex-row gap-20 lg:gap-[134px]">
          <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
            <div className="flex flex-col flex-1 gap-4">
              <h5 className="text-2xl font-semibold text-primaryIntense leading-[133%]">
                Da aposentadoria ao sonho da casa nova
              </h5>

              <p className="text-base text-white font-medium leading-[125%]">
                Com a previdência privada Mio, você poderá alcançar seus
                objetivos e realizar sonhos, sem precisar abrir mão de viver o
                seu presente.
              </p>

              <p className="text-base text-white font-medium leading-[125%]">
                Qualquer que seja seu objetivo, nós temos um plano.
              </p>
            </div>
          </Animation>

          <Animation ref={refView} animationConfig={rightToLeftSpring(600)}>
            <div className="flex flex-col flex-1 gap-1">
              <h6 className="text-xl font-semibold text-primaryIntense leading-[140%]">
                Faça tudo o que precisar direto no app
              </h6>

              <p className="text-sm text-white leading-[125%]">
                Contrate seu plano, acompanhe seus rendimentos, faça novas
                aplicações e fique mais perto do seu objetivo com o nosso o app.
              </p>

              <h6 className="text-xl font-semibold text-primaryIntense leading-[140%] mt-8">
                Planos personalizados para cada cliente
              </h6>

              <p className="text-sm text-white leading-[125%]">
                Receba sugestões de planos feitas por nosso Assessor de
                Previdência, que analisa diversos cenários para propor a melhor
                alocação para os seus objetivos.
              </p>
            </div>
          </Animation>
        </div>
      </Section>
    </Background>
  );
};

export default Section2;
