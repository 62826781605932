import { Section } from "components/layout/Section";
import iphone13 from "assets/images/for-you/iphone-13.png";
import iconPrevidence1 from "assets/svg/for-you-icon-1.svg";
import iconPrevidence2 from "assets/svg/for-you-icon-2.svg";
import iconPrevidence3 from "assets/svg/for-you-icon-3.svg";
import iconPrevidence4 from "assets/svg/for-you-icon-4.svg";
import iconPrevidence5 from "assets/svg/for-you-icon-5.svg";
import PrevidenceItem from "components/organisms/for-you/PrevidenceItem";
import appStoreBadge from "assets/images/app-store-badge.svg";
import googlePlayBadge from "assets/images/google-play-badge.svg";
import qrCode from "assets/images/qrCode.png";
import { useEffect, useState } from "react";
import { useInView } from "react-spring";
import Animation from "components/atoms/Animation";
import { fadeIn } from "utils/Animation.util";

const Section6 = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);
  const [refView, inView] = useInView({ once: true });

  useEffect(() => {
    if (inView) setSectionVisible(true);
  }, [inView]);

  const leftToRightSpring = (duration: number) => ({
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? "translateX(0)" : "translateX(-200px)",
    config: { duration },
  });

  return (
    <Animation ref={refView} animationConfig={fadeIn(600)}>
      <Section>
        <div className="flex flex-col gap-4 lg:gap-24 lg:flex-row">
          <div className="flex flex-col items-start max-w-[628px] flex-1">
            <Animation ref={refView} animationConfig={leftToRightSpring(600)}>
              <h5 className="mb-4 text-[40px] font-semibold text-primaryTextBase">
                Uma previdência pensada para o mundo digital
              </h5>
            </Animation>

            <Animation ref={refView} animationConfig={leftToRightSpring(800)}>
              <p className="text-xl font-semibold text-primaryTextBase mb-14">
                O aplicativo Mio Vinci Partners te deixará no controle de tudo.
                Veja o que você pode fazer em nosso app.
              </p>
            </Animation>

            <Animation ref={refView} animationConfig={leftToRightSpring(900)}>
              <PrevidenceItem
                icon={iconPrevidence1}
                title="Conheça seu perfil de investidor"
                description="Responda algumas perguntas sobre seu comportamento frente
            algumas situações relacionadas a investimentos e conheça o
            perfil de investidor mais próximo da sua realidade."
              />
            </Animation>

            <Animation ref={refView} animationConfig={leftToRightSpring(1000)}>
              <PrevidenceItem
                icon={iconPrevidence2}
                title="Descubra o seu plano ideal"
                description="Chega de investir em fundos que só dão dinheiro para quem vende o plano para você. Aqui o seu plano de previdência é pensado para render e ajustado para os seus objetivos e perfil."
              />
            </Animation>

            <Animation ref={refView} animationConfig={leftToRightSpring(1100)}>
              <PrevidenceItem
                icon={iconPrevidence3}
                title="Contrate e comece a investir"
                description="Em poucos minutos, contrate o seu plano sem aquelas letras miúdas ou taxas escondidas e comece a sua jornada financeira rumo aos seus objetivos de futuro."
              />
            </Animation>

            <Animation ref={refView} animationConfig={leftToRightSpring(1200)}>
              <PrevidenceItem
                icon={iconPrevidence4}
                title="Traga a sua previdência para o Mio"
                description="Para trazer a sua previdência atual que está em outra instituição para o Mio, basta nos informar alguns dados sobre o plano, o valor que você deseja trazer e deixar o restante com a gente."
              />
            </Animation>

            <Animation ref={refView} animationConfig={leftToRightSpring(1300)}>
              <PrevidenceItem
                icon={iconPrevidence5}
                title="Tenha total controle sobre o seu plano"
                description="Realize ajustes a qualquer tempo. Altere o valor da contribuição, acompanhe os rendimentos ou quem sabe impulsione seus investimentos com aplicações extras."
              />
            </Animation>
          </div>

          <div className="flex flex-col">
            <img src={iphone13} alt="iPhone 13" className="mb-7" />

            <div className="flex-col items-center hidden gap-4 text-center lg:block">
              <h4 className="text-base font-semibold text-primaryTextBase">
                Baixe o app e comece a investir
              </h4>

              <div className="flex flex-col items-center gap-3 mt-5 lg:flex-row lg:items-start lg:gap-7 ml-36">
                <div className="mt-1">
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlayBadge}
                      alt="Google Play"
                      style={{ width: "150px" }}
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appStoreBadge}
                      alt="App Store"
                      className="mt-3"
                      style={{ width: "150px" }}
                    />
                  </a>
                </div>

                <div className="hidden ml-3 lg:ml-0 lg:block">
                  <img src={qrCode} alt="QR Code para download" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-4 mb-8 text-center lg:hidden lg:justify-start">
            <div className="flex flex-col gap-4">
              <h4 className="text-base font-semibold text-primaryTextBase">
                Baixe o app e comece a investir
              </h4>

              <div className="flex justify-between gap-3 lg:gap-7">
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.vinciprev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlayBadge} alt="Google Play" />
                </a>

                <a
                  href="https://apps.apple.com/br/app/mio-vinci-partners/id6444894937"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appStoreBadge} alt="App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Animation>
  );
};

export default Section6;
