import { useSpring, animated } from "react-spring";
import React, { forwardRef } from "react";

interface Props {
  animationConfig: Record<string, any>;
  children: React.ReactNode;
}

const Animation = forwardRef<HTMLDivElement, Props>(
  ({ animationConfig, children }, ref) => {
    const props = useSpring(animationConfig);

    return (
      <animated.div ref={ref} style={props}>
        {children}
      </animated.div>
    );
  }
);

export default Animation;
