import { Section } from "components/layout/Section";
import React from "react";
import { Footer } from "components/templates/shared/Footer";
import aboutSectionBadge from "assets/images/about-section.png";
import ceoImage from "assets/images/ceo-image.png";
import HeroPages from "components/organisms/HeroPages";

const About = () => {
  return (
    <div className="overflow-auto antialiased text-black">
      <HeroPages title="Sobre" />

      <Section>
        <div className="flex flex-col mb-4 lg:flex-row lg:gap-20">
          <div className="flex flex-col flex-1 text-center pt-7 lg:text-left">
            <h5 className="mb-6 text-xl lg:text-4xl font-semibold text-neutralTextLight leading-[120%] lg:text-left">
              Com a previdência MIO, você aproveita todo o potencial do seu
              dinheiro para{" "}
              <span className="text-primary">
                alcançar seus objetivos com mais simplicidade
              </span>
            </h5>

            <p className="text-base lg:text-lg text-neutralTextLightIntense ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua
              Utenimad minim veniam, quis nostrud exercitation dolore magna
            </p>
          </div>

          <div className="flex items-center justify-center flex-1 mt-10 lg:mt-0 lg:justify-left">
            <img
              className="w-[80%] md:w-[320px] lg:w-[410px] mb-10 lg:mb-0"
              src={aboutSectionBadge}
              alt="Google Play"
            />
          </div>
        </div>
      </Section>

      <Section>
        <div className="flex flex-col gap-20 mb-4 lg:px-20 lg:flex-row">
          <div className="flex items-center justify-center lg:justify-start">
            <img className="w-[328px]" src={ceoImage} alt="CEO" />
          </div>

          <div className="flex flex-col flex-1 gap-8 pt-4 text-center lg:text-left">
            <h5 className="mb-6 text-xl lg:text-4xl font-semibold text-neutralTextLight leading-[120%]">
              "Com a previdência MIO, você aproveita todo o potencial do seu
              dinheiro para alcançar seus objetivos com mais simplicidade"
            </h5>

            <p className="text-lg text-neutralTextLightIntense">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua
              Utenimad minim veniam, quis nostrud exercitation dolore magna
            </p>

            <div className="flex flex-col gap-1">
              <p className="text-2xl font-semibold text-primary">
                Albert William
              </p>
              <p className="font-medium text-neutralTextLightIntense">
                Project Manager / CEO
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 justify-around bg-[#FEF3F5] rounded-3xl py-16 px-10">
          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">320+</p>
            <p className="text-lg text-neutralTextLightIntense">
              Complete Projects
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">25+</p>
            <p className="text-lg text-neutralTextLightIntense">
              Cooperation company
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-4xl font-semibold text-neutralTextLight">120+</p>
            <p className="text-lg text-neutralTextLightIntense">
              Happy Clients
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="flex flex-col items-center gap-4 text-center lg:px-24">
          <p className="text-lg font-medium text-primary">Nossa visão</p>
          <h5 className="mb-4 text-2xl font-semibold lg:text-3xl text-neutralTextLight">
            Provide The Creative Solutions
          </h5>
          <p className="text-lg text-neutralTextLightIntense">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad
            minim veniam, quis nostrud exercitation dolore magna
          </p>
        </div>
      </Section>

      <Section>
        <div className="flex flex-col gap-4 lg:gap-8 relative bg-[url('../assets/images/image-bg.png')] bg-left lg:bg-center bg-no-repeat bg-cover h-60 w-[90%] lg:w-auto lg:h-[467px] px-10 py-10 lg:px-20 pb-36 mx-auto lg:mx-0 rounded-3xl">
          <h3 className="text-xl md:text-3xl lg:text-5xl text-white font-semibold max-w-[500px] leading-[120%]">
            Entenda hoje tudo o que seu dinheiro pode ser amanhã
          </h3>

          <p className="text-base font-medium text-white">
            Comece a investir hoje e garanta a sua tranquilidade no futuro.
          </p>
        </div>
      </Section>

      <Footer />
    </div>
  );
};

export default About;
